import FluxDatePicker from 'shared/components/FluxDatePicker';
import { datePickerTypes } from 'shared/constants/graphingAndStatisticsConstants';

import { START_DATE_HEADING, START_DATE_SUBHEADING } from 'reporting/constants/reportingConstants';

const NewReportDatePicker = ({
  finalizedDates,
  pickerType = datePickerTypes.RANGE,
  popupClasses,
  errored: { field: errorField } = { field: '' },
  timeIsLaterThanNow,
  ...otherDatePickerProps
}) => {
  const hasError = errorField === 'startTime' || errorField === 'endTime';

  const rangePickerProps = {
    panelTitle: 'Select report range',
    panelSubtitle: 'Click on any two days to create a range.',
  };

  const singlePickerProps = {
    panelTitle: 'Select report date',
    panelSubtitle: 'Click on any day to create a report',
    buttonLabel: 'Choose Send Day',
    pickerType: datePickerTypes.SINGLE,
  };

  return (
    <div>
      <h3 className={hasError && !finalizedDates ? 'errored-heading' : ''}>
        { pickerType === datePickerTypes.RANGE ? 'Date Range' : START_DATE_HEADING } { ' ' }
        <span className="required-asterisk">*</span>
      </h3>
      <p className="section-subtext">
        {
        pickerType === datePickerTypes.RANGE
          ? 'The range of collected data to report.'
          : START_DATE_SUBHEADING
        }
      </p>
      <FluxDatePicker
        restrictDates
        errorField={errorField}
        pickerType={pickerType}
        {...otherDatePickerProps}
        singlePickerButtonLabels={{}}
        finalizedDates={finalizedDates}
        rangePickerProps={rangePickerProps}
        singlePickerProps={singlePickerProps}
        timeIsLaterThanNow={timeIsLaterThanNow}
        popupClasses={`new-report-date-picker ${popupClasses}`}
      />
    </div>
  );
};

export default NewReportDatePicker;
