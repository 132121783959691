import {
  MINIMUM_RUN_TIME_PERCENTAGE,
  MISSING_FIELD_VALUE_PLACEHOLDER,
} from 'shared/constants/altitudeConstants';
import { connectivityTooltips } from 'shared/constants/assetConstants';

export const getAssetLinkUrl = (asset) => `/asset/${asset.org_id}/${asset.site_id}/${asset.asset_id}/${asset.device_id}`;

export const getRunTimePercentageClassAndText = (percentage) => ([
  percentage >= MINIMUM_RUN_TIME_PERCENTAGE
    ? 'ok' : 'error',
  !percentage || percentage !== MISSING_FIELD_VALUE_PLACEHOLDER ? `${percentage}%` : MISSING_FIELD_VALUE_PLACEHOLDER,
]);

export const getAssetStatusClassAndTooltip = (ipAddress, connectivityStatus) => {
  if (!ipAddress) return ['error', connectivityTooltips.disconnected];
  if (connectivityStatus === 'connected') return ['ok', connectivityTooltips.connected];
  if (connectivityStatus === 'disconnected') return ['error', connectivityTooltips.disconnected];
  return ['unknown', connectivityTooltips.unknown];
};
