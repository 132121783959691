import { URL_PREFIX } from 'shared/repositories/urls';
import defaultDriverLayouts from 'single-asset/constants/defaultDriverLayouts';

interface LegacyAssetInfo {
  asset_id: number;
  device_id: number;
  org_id: number;
  site_id: number;
  asset_name: string;
  driver: 'GED' | 'ELEC';
}

export interface ChartLayout {
  order: number;
  title: string;
  chartValues: {
    key: string;
    tag: string;
    color: string;
    label: string;
  }[];
}

interface Layout {
  id: string;
  parentId: string;
  unitId: string;
  driver: 'GED' | 'ELEC' | '*' | null;
  charts: ChartLayout[];
}

export interface ICharting {
  layouts?: Layout[];
}

export default class Charting {
  layouts?: Layout[];

  layoutFetchAttempts: number;

  constructor({
    layouts,
  }: ICharting) {
    this.layouts = layouts;
    this.layoutFetchAttempts = 0;
  }

  setLayouts(layouts: Layout[]) {
    this.layouts = layouts;
  }

  // unitId is the unit name with ONLY numeric characters
  getUnitLayout(unitId: string, driver: 'GED' | 'ELEC') {
    if (!this.layouts) {
      return defaultDriverLayouts[driver];
    }
    const unitLayout = this.layouts?.find((layout) => layout.unitId === unitId);

    if (unitLayout) {
      return unitLayout;
    }

    const driverLayout = this.layouts?.find((layout) => layout.driver === driver && (layout.unitId === '*' || layout.unitId === null));
    if (driverLayout) {
      return driverLayout;
    }

    const allUnitsLayout = this.layouts?.find((layout) => layout.unitId === '*' && (layout.driver === '*' || layout.driver === null));
    if (allUnitsLayout) {
      return allUnitsLayout;
    }

    return defaultDriverLayouts[driver];
  }

  getUnitLayoutAndApiChartBodies({
    asset_id,
    device_id,
    org_id,
    site_id,
    asset_name,
    driver,
  }: LegacyAssetInfo) {
    const unitId = asset_name.replace(/\D/g, '');
    const unitLayout = this.getUnitLayout(unitId, driver);
    const transformedLayout = unitLayout.charts.map((chart) => ({
      series: chart.chartValues.map((chartValue) => ({
        asset_id,
        device_id,
        org_id,
        site_id,
        tag_id: chartValue.tag,
        label: chartValue.label,
        formattedLabel: chartValue.label.replace(/ /g, '-'),
        map: { epoch_ms: 'x', uom: 'uom', val: 'y' },
        agg: {
          max: ['y'],
          min: ['y'],
          mean: ['y'],
        },
      })),
      url: `${chart.chartValues.map((cv) => cv.label.replace(/ /g, '-')).join(',')}`,
    }));
    return { layout: unitLayout, chartBodies: transformedLayout };
  }

  async fetchLayouts(userEmail: string, token: string) {
    this.layoutFetchAttempts += 1;
    try {
      const response = await fetch(`https://${URL_PREFIX}layouts.api.axil.ai/${userEmail}/layouts`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      const layouts = await response.json();
      this.layouts = layouts;
    } catch (error) {
      this.layouts = [];
    }
  }

  // async updateLayout(layoutId: string, newLayout: Layout) {
  //   // patch to update layout
  // }

  // async createLayout(newLayout: Layout) {
  //   // post to create layout
  // }
}
