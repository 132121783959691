import React from 'react';
import { IoAddOutline, IoCloseOutline } from 'react-icons/io5';

import MathCard from 'single-asset/components/emissions-calculator/MathCard';

import { IEmissionData, IGasAnalysisData } from 'shared/constants/emissionsCalculatorConstants';

import './MethaneBreakdownCard.scss';

interface MethaneBreakdownCardProps {
  emissionsData: IEmissionData | null;
  gasAnalysis: IGasAnalysisData | null;
}

const MethaneBreakdownCard: React.FC<MethaneBreakdownCardProps> = ({ emissionsData, gasAnalysis }) => {
  const methaneGWP = 28;
  const formattedFlareEfficiency = 0.98;
  const methaneDensityConstant = 0.01913;

  const volumeOfAllGas = emissionsData?.volume?.toFixed(4);
  const formattedMethaneTotal = emissionsData?.methaneVented?.toFixed(4);
  const gasMolPercent = (gasAnalysis?.gasMolPercents?.methanePct || 0) * 100;

  return (
    <section className="methane-emission-breakdown-card">
      <div className="methane-emission-breakdown-header">
        <p className="methane-emission-total">{formattedMethaneTotal}</p>
        <p className="methane-emission-description">Methane Emissions Abated</p>
      </div>
      <div className="methane-emission-breakdown-specifics">
        <div className="emissions-specifics-row">
          <MathCard
            bordered
            value={volumeOfAllGas}
            label="Volume of All Gas"
          />
          <IoCloseOutline size={16} />
          <MathCard
            bordered
            label="Flare Efficiency"
            value={`1 - ${formattedFlareEfficiency}`}
          />
          <IoCloseOutline size={16} />
          <MathCard
            bordered
            label="Gas Density"
            value={methaneDensityConstant}
          />
          <IoAddOutline size={16} />
        </div>
        <div className="emissions-specifics-row">
          <MathCard
            bordered
            label="Gas Mol %"
            value={gasMolPercent}
          />
          <IoCloseOutline size={16} />
          <MathCard
            bordered
            label="GWP"
            value={methaneGWP}
          />
        </div>
      </div>
    </section>
  );
};

export default MethaneBreakdownCard;
