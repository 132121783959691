import React, { useEffect, useState } from 'react';
import { IoAddOutline, IoCloseOutline } from 'react-icons/io5';

import FluxSelect from 'shared/components/FluxSelect';

import { gasSelectOptions } from 'single-asset/constants/carbonCaptureConstants';
import MathCard from 'single-asset/components/carbon-capture-calculator/modal/components/MathCard';

import './EmissionBreakdownCard.scss';

const EmissionBreakdownCard = ({
  handleClose,
  currentCarbonDetails,
}) => {
  const [gasMoleculeCount, setGasMoleculeCount] = useState(1);
  const [currentGasOption, setCurrentGasOption] = useState(gasSelectOptions[0]);
  const [currentMolPercent, setCurrentMolPercent] = useState(currentCarbonDetails?.carbonDioxidePct?.toFixed(4));

  useEffect(() => {
    switch (currentGasOption.value) {
      case 'Carbon Dioxide':
        setGasMoleculeCount(1);
        setCurrentMolPercent(currentCarbonDetails?.carbonDioxidePct?.toFixed(4));
        break;
      case 'Nitrogen':
        setGasMoleculeCount(0);
        setCurrentMolPercent(currentCarbonDetails?.nitrogenPct?.toFixed(4));
        break;
      case 'Ethane':
        setGasMoleculeCount(2);
        setCurrentMolPercent(currentCarbonDetails?.ethanePct?.toFixed(4));
        break;
      case 'Methane':
        setGasMoleculeCount(1);
        setCurrentMolPercent(currentCarbonDetails?.methanePct?.toFixed(4));
        break;
      case 'Butane':
        setGasMoleculeCount(4);
        setCurrentMolPercent(currentCarbonDetails?.butanePct?.toFixed(4));
        break;
      case 'I-Butane':
        setGasMoleculeCount(4);
        setCurrentMolPercent(currentCarbonDetails?.iButanePct?.toFixed(4));
        break;
      case 'Pentane':
        setGasMoleculeCount(5);
        setCurrentMolPercent(currentCarbonDetails?.pentanePct?.toFixed(4));
        break;
      case 'I-Pentane':
        setGasMoleculeCount(5);
        setCurrentMolPercent(currentCarbonDetails?.iPentanePct?.toFixed(4));
        break;
      case 'Hexane':
        setGasMoleculeCount(6);
        setCurrentMolPercent(currentCarbonDetails?.hexanePct?.toFixed(4));
        break;
      default: break;
    }
  }, [currentGasOption]);

  return (
    <section className="emission-breakdown-card">
      <div className="emission-breakdown-header">
        <div className="emission-breakdown-header-content">
          <p className="emission-total">{currentCarbonDetails?.totalFlaredCo2Mt?.toFixed(4)}</p>
          <p className="emission-description">Flared Emissions Abated</p>
        </div>
        <div className="breakdown-close" onClick={() => handleClose()}>
          <IoCloseOutline size={24} color="#848383" />
        </div>
      </div>
      <FluxSelect
        width="225px"
        showResetButton={false}
        options={gasSelectOptions}
        handleChange={setCurrentGasOption}
        defaultOption={gasSelectOptions[0]}
      />
      <div className="emission-breakdown-specifics">
        <div className="emissions-specifics-row">
          <MathCard
            bordered
            label={<span>Volume of All Gas</span>}
            value={currentCarbonDetails?.volume.toFixed(4)}
          />
          <IoCloseOutline size={16} />
          {currentGasOption.value !== 'Carbon Dioxide'
            && (
              <>
                <MathCard
                  bordered
                  value="0.98"
                  label={<span>Flare Efficiency</span>}
                />
                <IoCloseOutline size={16} />
              </>
            )}
          <MathCard
            bordered
            value={0.05262}
            label={<span>CO<sub>2</sub> Density</span>}
          />
          <IoAddOutline size={16} />
        </div>
        <div className="emissions-specifics-row">
          <MathCard
            bordered
            value={currentMolPercent}
            label={<span>Gas Mol %</span>}
          />
          <IoCloseOutline size={16} />
          <MathCard
            bordered
            value={gasMoleculeCount}
            label={<span>Carbon Molecules</span>}
          />
        </div>
      </div>
    </section>
  );
};

export default EmissionBreakdownCard;
