import { useEffect, useState } from 'react';

import { assetFieldNames } from 'shared/constants/altitudeConstants';
import { getFilteredAssetList } from 'shared/helpers/assetHelpers';
import FluxVirtualizedTable from 'shared/components/flux-virtualized-table/FluxVirtualizedTable';

import { generateUnitViewRedirect, openRedirect } from 'dashboard/helpers/dashboardHelpers';

import NotificationSettings from 'account-settings/components/notifications/NotificationSettings';
import { generateNotificationTableColumns } from 'account-settings/helpers/notificationTableHelpers';

import './NotificationsTabsContent.scss';

const NotificationsTabsContent = ({
  assets,
  dndSettings,
  preferences,
  activeSection,
  setDndSettings,
  filteredAssets,
  enterpriseObjects,
  userSubscriptions,
  setFilteredAssets,
  updateDndPreferences,
  updateReceivingTexts,
  updateReceivingEmails,
  showConfirmationButtons,
  setModifiedSubscriptions,
  setShowConfirmationButtons,
}) => {
  const [assetFilterText, setAssetFilterText] = useState('');
  const [subscribedTableData, setSubscribedTableData] = useState([]);
  const [unsubscribedTableData, setUnsubscribedTableData] = useState([]);

  const generateLinkForTableRow = (item) => {
    const relatedEnterpriseObject = enterpriseObjects
      ?.find((entry) => entry?.name?.toString() === item?.asset_name);

    const link = generateUnitViewRedirect(relatedEnterpriseObject);
    return link;
  };

  const selectUnitView = (event, item) => {
    const link = generateLinkForTableRow(item);
    openRedirect(event, link);
  };

  const clearDndSettings = () => {
    const newSettings = {
      ...preferences,
      dnd: false,
      dnd_start: null,
      dnd_stop: null,
    };

    setDndSettings(newSettings);
    updateDndPreferences();
  };

  const handleDndStopChange = (_, option) => {
    setDndSettings({
      ...dndSettings,
      dnd_stop: option.value,
    });

    setShowConfirmationButtons(true);
  };

  const handleDndStartChange = (_, option) => {
    setDndSettings({
      ...dndSettings,
      dnd_start: option.value,
    });

    setShowConfirmationButtons(true);
  };

  const addSubscriptionDetail = (record, label) => {
    const newSubscribedTableData = subscribedTableData?.map((item) => {
      if (item?.key?.toString() === record?.key) {
        const newItem = { ...item };
        if (label === 'all') {
          const newState = !newItem.all;
          newItem.all = newState;
          newItem.info = newState;
          newItem.debug = newState;
          newItem.danger = newState;
          newItem.success = newState;
          newItem.warning = newState;
        } else if (label in newItem) {
          newItem[label] = !newItem[label];
          if (newItem.all === true && newItem[label] === false) {
            newItem.all = false;
          }
        }
        return newItem;
      }
      return item;
    });

    const recordFound = newSubscribedTableData.some((item) => item.key.toString() === record.key);
    if (!recordFound) {
      const newItem = {
        key: record.key,
        all: false,
        info: false,
        debug: false,
        danger: false,
        success: false,
        warning: false,
      };
      if (label === 'all') {
        newItem.all = true;
        newItem.info = true;
        newItem.debug = true;
        newItem.danger = true;
        newItem.success = true;
        newItem.warning = true;
      } else {
        newItem[label] = true;
      }
      newSubscribedTableData.push(newItem);
    }

    const newUnsubscribedTableData = unsubscribedTableData?.map((item) => {
      if (item?.key?.toString() === record?.key) {
        const newItem = { ...item };
        if (label === 'all') {
          const newState = !newItem.all;
          newItem.all = newState;
          newItem.info = newState;
          newItem.debug = newState;
          newItem.danger = newState;
          newItem.success = newState;
          newItem.warning = newState;
        } else if (label in newItem) {
          newItem[label] = !newItem[label];
          if (newItem.all === true && newItem[label] === false) {
            newItem.all = false;
          }
        }
        return newItem;
      }
      return item;
    });

    const newFormattedSubscriptions = newSubscribedTableData?.map((entry) => ({
      [entry.key]: {
        info: entry.info,
        debug: entry.debug,
        danger: entry.danger,
        success: entry.success,
        warning: entry.warning,
      },
    }));

    const updateSubscriptionsObject = newFormattedSubscriptions.reduce((acc, item) => ({ ...acc, ...item }), {});

    showConfirmationButtons();
    setSubscribedTableData(newSubscribedTableData);
    setUnsubscribedTableData(newUnsubscribedTableData);
    setModifiedSubscriptions(updateSubscriptionsObject);
  };

  const toggleSubscriptionDetail = (record, label) => {
    const newSubscribedTableData = subscribedTableData?.map((item) => {
      if (item?.key?.toString() === record?.key) {
        const newItem = { ...item };
        if (label === 'all') {
          const newState = !newItem.all;
          newItem.all = newState;
          newItem.info = newState;
          newItem.debug = newState;
          newItem.danger = newState;
          newItem.success = newState;
          newItem.warning = newState;
        } else if (label in newItem) {
          newItem[label] = !newItem[label];

          if (newItem.all === true && newItem[label] === false) {
            newItem.all = false;
          }
        }
        return newItem; // Return the new item
      }
      return item;
    });

    const newFormattedSubscriptions = newSubscribedTableData?.map((entry) => ({
      [entry.key]: {
        info: entry.info,
        debug: entry.debug,
        danger: entry.danger,
        success: entry.success,
        warning: entry.warning,
      },
    }));

    const updateSubscriptionsObject = newFormattedSubscriptions
      .reduce((acc, item) => ({ ...acc, ...item }), {});

    showConfirmationButtons();
    setSubscribedTableData(newSubscribedTableData);
    setModifiedSubscriptions(updateSubscriptionsObject);
  };

  const filterFields = [
    assetFieldNames.MODEL,
    assetFieldNames.ORG_NAME,
    assetFieldNames.SITE_NAME,
    assetFieldNames.ASSET_NAME,
  ];

  const notificationTableColumns = generateNotificationTableColumns(
    {
      activeSection,
      addSubscriptionDetail,
      toggleSubscriptionDetail,
    },
  );

  useEffect(() => {
    setFilteredAssets(getFilteredAssetList(assets, null, assetFilterText, filterFields));
  }, [assetFilterText]);

  useEffect(() => {
    if (userSubscriptions) {
      const subscriptionKeys = Object.keys(userSubscriptions);
      const formattedSubscriptions = subscriptionKeys.map((key) => ({
        key,
        info: userSubscriptions[key].info,
        debug: userSubscriptions[key].debug,
        danger: userSubscriptions[key].danger,
        success: userSubscriptions[key].success,
        warning: userSubscriptions[key].warning,
        all: Object.values(userSubscriptions[key]).slice(1).every((value) => value),
      }));

      const activeSubscriptions = formattedSubscriptions
        .filter((item) => Object.values(item).slice(1).some((value) => value));

      const activeSubscriptionIds = activeSubscriptions?.map((sub) => sub.key);

      const subscribedUnits = filteredAssets
        ?.filter((asset) => activeSubscriptionIds.includes(asset.asset_id.toString()));

      const unsubscribedUnits = filteredAssets
        ?.filter((asset) => !activeSubscriptionIds.includes(asset.asset_id.toString()));

      const subscribedUnitRows = subscribedUnits.map((unitSubscription) => {
        const matchingFormattedSubscription = formattedSubscriptions
          .find((formattedSubscription) => formattedSubscription.key === unitSubscription.asset_id.toString());

        return {
          ...matchingFormattedSubscription,
          org_name: unitSubscription.org_name,
          site_name: unitSubscription.site_name,
          asset_name: unitSubscription.asset_name,
        };
      });

      const unsubscribedUnitRows = unsubscribedUnits.map((unit) => ({
        all: false,
        info: false,
        debug: false,
        danger: false,
        success: false,
        warning: false,
        key: unit.asset_id.toString(),
        org_name: unit.org_name,
        site_name: unit.site_name,
        asset_name: unit.asset_name,
      }));

      setSubscribedTableData(subscribedUnitRows);
      setUnsubscribedTableData(unsubscribedUnitRows);
    }
  }, [userSubscriptions]);

  return (
    <div className="notifications-tabs-content">
      <NotificationSettings
        dndSettings={dndSettings}
        preferences={preferences}
        activeSection={activeSection}
        clearDndSettings={clearDndSettings}
        setAssetFilterText={setAssetFilterText}
        handleDndStopChange={handleDndStopChange}
        handleDndStartChange={handleDndStartChange}
        updateReceivingTexts={updateReceivingTexts}
        updateReceivingEmails={updateReceivingEmails}
      />
      <FluxVirtualizedTable
        filterString={assetFilterText}
        handleRowClick={selectUnitView}
        columns={notificationTableColumns}
        generateLinkForTableRow={generateLinkForTableRow}
        data={activeSection === 'My Notifications'
          ? subscribedTableData
          : unsubscribedTableData}
      />
    </div>
  );
};

export default NotificationsTabsContent;
