import React from 'react';

import { IEmissionData } from 'shared/constants/emissionsCalculatorConstants';

interface CurrentEmissionsSectionProps {
  timeFrame: string,
  emissionsData: IEmissionData | null,
}

const CurrentEmissionsSection: React.FC<CurrentEmissionsSectionProps> = ({
  timeFrame,
  emissionsData,
}) => {
  const currentNetAbated = (
    Number(emissionsData?.flared?.total || 0)
    + Number(emissionsData?.methaneVented || 0)
    - Number(emissionsData?.driveEmissions || 0));

  return (
    <div className="current-emissions-section">
      <span className="time-frame">{timeFrame}</span>
      <span className="current-value">{currentNetAbated?.toFixed(4)}</span>
      <span className="description">Net Emissions Abated (Metric Tons CO2e)</span>
    </div>
  );
};

export default CurrentEmissionsSection;
