/* eslint-disable consistent-return */
const ENV_URL = process.env.REACT_APP_BUILD_ENV === 'production'
  ? 'https://'
  : 'https://dev-';

const QUERIES_URL = `${ENV_URL}gas-analysis.api.axil.ai`;
const GAS_ANALYSIS_URL = `${QUERIES_URL}/gas-analysis`;

export const getGasAnalysisForUnit = async ({
  accessToken,
  gasAnalysisId,
}) => {
  const FORMATTED_URL = `${GAS_ANALYSIS_URL}/${gasAnalysisId}`;

  try {
    const response = await fetch(FORMATTED_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching gas analysis details: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.log('Error fetching gas analysis details.');
  }
};

export const postGasAnalysis = async ({
  body,
  accessToken,
  gasAnalysisId = null,
}) => {
  const FORMATTED_URL = `${GAS_ANALYSIS_URL}${gasAnalysisId ? `/${gasAnalysisId}` : ''}`;

  try {
    const response = await fetch(
      FORMATTED_URL,
      {
        body: JSON.stringify(body),
        method: gasAnalysisId ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'If-Match': gasAnalysisId ? body?.version : '',
        },
      },
    );

    if (!response.ok) {
      throw new Error(`Error fetching gas analysis details: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.log('Error fetching gas analysis details.');
  }
};
