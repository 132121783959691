import {
  IoCalculator,
  IoGrid,
  IoGridOutline,
  IoMail,
  IoMailOutline,
  IoNotifications,
  IoNotificationsOutline,
  IoStatsChart,
  IoStatsChartOutline,
  IoTerminal,
  IoTerminalOutline,
  IoCalculatorOutline,
} from 'react-icons/io5';

/*
  For easy development access, add new dev routes to devLinks and change path
  {
    key: 'DevelopmentPage',
    icon: 'IoTerminal',
    label: 'DevelopmentPage',
    path: '/pathToDevPage',
  },
*/

export const getNavLinks = (homepage, hasEmissionsAccess = false) => {
  const withEmissions = [
    {
      key: 'Dashboard',
      icon: 'IoGrid',
      label: 'Dashboard',
      path: `/${homepage ?? 'dashboard'}`,
    },
    {
      key: 'Emissions',
      icon: 'IoCalculator',
      label: 'Emissions',
      path: '/emissions',
    },
    {
      key: 'Reporting',
      icon: 'IoStatsChart',
      label: 'Reporting',
      path: '/reports',
    },
    {
      key: 'Notifications',
      icon: 'IoNotifications',
      label: 'Notifications',
      path: '/notifications',
    },
  ];
  const withoutEmissions = [
    {
      key: 'Dashboard',
      icon: 'IoGrid',
      label: 'Dashboard',
      path: `/${homepage ?? 'dashboard'}`,
    },
    {
      key: 'Emissions',
      icon: 'IoCalculator',
      label: 'Emissions',
      path: '/emissions',
    },
    {
      key: 'Reporting',
      icon: 'IoStatsChart',
      label: 'Reporting',
      path: '/reports',
    },
    {
      key: 'Notifications',
      icon: 'IoNotifications',
      label: 'Notifications',
      path: '/notifications',
    },
  ];

  return hasEmissionsAccess ? withEmissions : withoutEmissions;
};

const icons = {
  IoGrid,
  IoGridOutline,
  IoMail,
  IoMailOutline,
  IoNotifications,
  IoNotificationsOutline,
  IoStatsChart,
  IoStatsChartOutline,
  IoTerminal,
  IoTerminalOutline,
  IoCalculator,
  IoCalculatorOutline,
};

export const generateNavIcon = (iconName, isActive) => {
  const Icon = icons[`${iconName}${isActive ? '' : 'Outline'}`];
  return (
    <Icon />
  );
};
