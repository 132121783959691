import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Button,
  Dropdown,
  DateRangePicker,
} from '@flogistix/flo-ui';

import FluxTooltip from 'shared/components/FluxTooltip';

import {
  getEndDate,
  getStartDate,
  getOrgIdValue,
  getAreaIdValue,
  getSiteIdValue,
  removeDateParameters,
  removeOrgIdParameter,
  removeSiteIdParameter,
  removeAreaIdParameter,
} from 'dashboard/helpers/dashboardHelpers';

import { selectSession } from 'shared/reducers/sessionReducer';
import { parameterizeDate } from 'shared/helpers/emissionsHelpers';
import { getUniqueValuesWithLabel } from 'shared/helpers/unitHelpers';
import { fetchFilterAreas } from 'shared/repositories/filterAreasRepository';
import { IEmissionData } from 'shared/constants/emissionsCalculatorConstants';
import { getFleetEmissionDetails } from 'shared/repositories/emissionsCalculatorRepository';

import { IDropdownOption } from 'emissions/pages/EmissionsPage';

import './EmissionsFleetView.scss';

const today = new Date();
const pastDate = new Date();
pastDate.setDate(today.getDate() - 28);

interface EmissionsFleetViewProps { units: any[]; }

const EmissionsFleetView: React.FC<EmissionsFleetViewProps> = ({ units }) => {
  const session = useSelector(selectSession);

  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const currentUrl = `${pathname}${search}`;
  const newUrl = new URL(currentUrl, window.location.origin);

  const [emissionsDataPending, setEmissionsDataPending] = useState(false);
  const [emissionsData, setEmissionsData] = useState<IEmissionData | null>(null);

  const [endDate, setEndDate] = useState<Date>(today);
  const [startDate, setStartDate] = useState<Date>(pastDate);

  const [orgSearchString, setOrgSearchString] = useState('');
  const [orgDropdownOptions, setOrgDropdownOptions] = useState<IDropdownOption[]>([]);
  const [selectedOrgOption, setSelectedOrgOption] = useState<IDropdownOption | null>(null);

  const [areaSearchString, setAreaSearchString] = useState('');
  const [areaDropdownOptions, setAreaDropdownOptions] = useState<IDropdownOption[]>([]);
  const [selectedAreaOption, setSelectedAreaOption] = useState<IDropdownOption | null>(null);

  const [siteSearchString, setSiteSearchString] = useState('');
  const [siteDropdownOptions, setSiteDropdownOptions] = useState<IDropdownOption[]>([]);
  const [selectedSiteOption, setSelectedSiteOption] = useState<IDropdownOption | null>(null);

  const handleEmissionsDates = (newStartDate: Date | null, newEndDate: Date | null) => {
    if (!newStartDate || !newEndDate) {
      return;
    }

    const parameterizedEndDate = parameterizeDate(newEndDate as Date);
    const parameterizedStartDate = parameterizeDate(newStartDate as Date);

    const url = new URL(currentUrl, window.location.origin);
    url.searchParams.set('end_date', parameterizedEndDate);
    url.searchParams.set('start_date', parameterizedStartDate);
    navigate(url.pathname + url.search);
  };

  const handleEndDate = (newEndDate: Date | null) => {
    setEndDate(newEndDate as Date);
  };

  const handleStartDate = (newStartDate: Date | null) => {
    setStartDate(newStartDate as Date);
  };

  const clearDates = () => {
    setEndDate(today);
    setStartDate(pastDate);

    const datelessUrl = removeDateParameters(currentUrl);
    navigate(datelessUrl);
  };

  const handleOrgFilter = (selectOrgOption: IDropdownOption) => {
    newUrl.searchParams.set('org_id', selectOrgOption?.value as string);
    navigate(newUrl.pathname + newUrl.search);
  };

  const handleAreaFilter = (selectedOption: IDropdownOption) => {
    newUrl.searchParams.set('operating_area_id', selectedOption?.value as string);
    navigate(newUrl.pathname + newUrl.search);
  };

  const handleSiteFilter = (selectedOption: IDropdownOption) => {
    newUrl.searchParams.set('site_id', selectedOption?.value as string);
    navigate(newUrl.pathname + newUrl.search);
  };

  const removeOrgIdParam = () => {
    const noOrgUrl = removeOrgIdParameter(currentUrl);
    navigate(noOrgUrl);
  };

  const removeAreaIdParam = () => {
    const noAreaUrl = removeAreaIdParameter(currentUrl);
    navigate(noAreaUrl);
  };

  const removeSiteIdParam = () => {
    const noSiteUrl = removeSiteIdParameter(currentUrl);
    navigate(noSiteUrl);
  };

  const removeUrlFilters = () => {
    const basePath = pathname.split('/emissions')[0];
    const updatedUrl = `${basePath}/emissions`;

    navigate(updatedUrl);
  };

  const generateDropdowns = () => {
    const uniqueOrgNames = getUniqueValuesWithLabel('org_name', units);
    const uniqueSiteNames = getUniqueValuesWithLabel('site_name', units);

    const formattedOrgOptions = uniqueOrgNames?.map((entry) => ({
      label: entry?.label,
      value: entry?.org_id,
    }));

    const formattedSiteOptions = uniqueSiteNames?.map((entry) => ({
      label: entry?.label,
      value: entry?.site_id,
    }));

    setOrgDropdownOptions(formattedOrgOptions);
    setSiteDropdownOptions(formattedSiteOptions);
  };

  const getFilterAreas = async () => {
    try {
      const response = await fetchFilterAreas({ accessToken: session?.token });
      const responseData = await response.json();

      setAreaDropdownOptions(responseData);
    } catch (error) {
      console.error('Error fetching filter areas:', error);
    }
  };

  const getEmissionDetails = async (
    filters = [''],
    newEndDate = endDate,
    newStartDate = startDate,
  ) => {
    const formattedEndDate = parameterizeDate(newEndDate);
    const formattedStartDate = parameterizeDate(newStartDate);

    setEmissionsData(null);
    setEmissionsDataPending(true);

    try {
      const response = await getFleetEmissionDetails({
        filters,
        endDate: formattedEndDate,
        accessToken: session?.token,
        startDate: formattedStartDate,
      });

      if (!response?.ok) {
        throw new Error(`Fetch failed with status: ${response?.status}`);
      }

      const responseData = await response.json();
      setEmissionsData(responseData);
    } catch (error) {
      console.error('Error fetching emissions data:', error);
    } finally {
      setEmissionsDataPending(false);
    }
  };

  const getDisplayedText = () => {
    if (emissionsDataPending) {
      return 'Loading';
    }

    const currentNetAbated = (
      Number(emissionsData?.flared?.total || 0)
    + Number(emissionsData?.methaneVented || 0)
    - Number(emissionsData?.driveEmissions || 0));

    return (emissionsData ? currentNetAbated?.toFixed(4) : 'Data Error');
  };

  const currentDisplayedText = getDisplayedText();

  useEffect(() => {
    session?.token && getFilterAreas();
    units?.length && generateDropdowns();
  }, [units, session]);

  useEffect(() => {
    if (!search?.length) {
      setSelectedOrgOption(null);
      setSelectedAreaOption(null);
      setSelectedSiteOption(null);

      setEndDate(today);
      setStartDate(pastDate);
      return;
    }

    const startDateParam = getStartDate(search);
    startDateParam ? setStartDate(new Date(startDateParam)) : setStartDate(pastDate);

    const endDateParam = getEndDate(search);
    endDateParam ? setEndDate(new Date(endDateParam)) : setEndDate(today);

    const paramOrgId = getOrgIdValue(search);
    if (paramOrgId) {
      const currentOrg: any = units?.find((unit: any) => Number(unit?.org_id) === Number(paramOrgId));
      const currentOrgOption: IDropdownOption = { label: currentOrg?.org_name, value: currentOrg?.org_id };

      currentOrgOption && setSelectedOrgOption(currentOrgOption);
    } else setSelectedOrgOption(null);

    const paramAreaId = getAreaIdValue(search);
    if (paramAreaId) {
      const currentAreaOption = areaDropdownOptions
        ?.find((option) => Number(option?.value) === Number(paramAreaId)) as IDropdownOption;

      currentAreaOption && setSelectedAreaOption(currentAreaOption);
    } else setSelectedAreaOption(null);

    const paramSiteId = getSiteIdValue(search);
    if (paramSiteId) {
      const currentSite = units?.find((unit) => unit?.site_id === Number(paramSiteId));
      const currentSiteOption: IDropdownOption = { label: currentSite?.site_name, value: currentSite?.site_id };

      currentSiteOption && setSelectedSiteOption(currentSiteOption);
    } else setSelectedSiteOption(null);
  }, [search, units, areaDropdownOptions]);

  useEffect(() => {
    if (session?.token) {
      const newFilters = [''];

      if (selectedOrgOption) {
        const orgFilterString = `&org_id=${selectedOrgOption?.value?.toString()}`;
        newFilters.push(orgFilterString);
      }

      if (selectedSiteOption) {
        const siteFilterString = `&site_id=${selectedSiteOption?.value?.toString()}`;
        newFilters.push(siteFilterString);
      }

      if (selectedAreaOption) {
        const areaFilterString = `&operating_area_id=${selectedAreaOption?.value?.toString()}`;
        newFilters.push(areaFilterString);
      }

      getEmissionDetails(newFilters, endDate, startDate);
    }
  }, [session, startDate, endDate, selectedOrgOption, selectedAreaOption, selectedSiteOption]);

  return (
    <section className="emissions-page-fleet-view">
      <div className="fleet-view-inputs">
        <DateRangePicker
          onClear={clearDates}
          selectedEndDate={endDate}
          selectedStartDate={startDate}
          setSelectedEndDate={handleEndDate}
          setSelectedStartDate={handleStartDate}
          onSelectionChange={handleEmissionsDates}
        />
        <Dropdown
          size="small"
          onSelect={handleOrgFilter}
          onClear={removeOrgIdParam}
          searchValue={orgSearchString}
          placeholder="Search Customers"
          className="fleet-view-org-select"
          value={selectedOrgOption?.label as string}
          onSearchChange={(input) => setOrgSearchString(input)}
          options={orgDropdownOptions?.filter((entry) => entry?.label?.toLowerCase()?.includes(orgSearchString.toLowerCase()))}
        />
        <Dropdown
          size="small"
          placeholder="Search Areas"
          onSelect={handleAreaFilter}
          onClear={removeAreaIdParam}
          searchValue={areaSearchString}
          className="fleet-view-area-select"
          value={selectedAreaOption?.label as string}
          onSearchChange={(input) => setAreaSearchString(input)}
          options={areaDropdownOptions?.filter((entry) => entry?.label?.toLowerCase()?.includes(areaSearchString.toLowerCase()))}
        />
        <Dropdown
          size="small"
          placeholder="Search Sites"
          onSelect={handleSiteFilter}
          onClear={removeSiteIdParam}
          searchValue={siteSearchString}
          className="fleet-view-area-select"
          value={selectedSiteOption?.label as string}
          onSearchChange={(input) => setSiteSearchString(input)}
          options={siteDropdownOptions?.filter((entry) => entry?.label?.toLowerCase()?.includes(siteSearchString.toLowerCase()))}
        />
        {selectedOrgOption || selectedSiteOption || selectedAreaOption || (endDate !== today && startDate !== pastDate)
          ? (
            <Button variation="ghost" onClick={removeUrlFilters}>
              Clear all
            </Button>
          )
          : <span />}
      </div>
      <div className="fleet-view-total-card">
        <section className="fleet-view-total-card-header">
          <p className="fleet-view-total-card-header-text">
            Total Emissions Abated (Metric Tons CO2e)
          </p>
          <FluxTooltip
            tooltip={null}
            circleSize="20px"
            circleColor="#101828"
            title="Total Emissions Abated"
            description="The total volume of all gas captured by vapor recovery."
          />
        </section>
        <p className="fleet-view-total-card-value">
          {currentDisplayedText}
        </p>
      </div>
    </section>
  );
};

export default EmissionsFleetView;
