import { assetFieldNames } from 'shared/constants/altitudeConstants';

import { getDashboardRuntimeStatColor } from 'dashboard/helpers/dashboardHelpers';
import { getFormattedElapsedTime } from 'shared/helpers/assetHelpers';

export const DASHBOARD_ID = 1;
export const ASSET_LIST_TABLE_ROW_HEIGHT = 64;
export const MINIMUM_AUTOCOMPLETE_FILTER_LENGTH = 2;

export const assetListTableColumns = [
  {
    sortable: true,
    title: 'Customer',
    dataIndex: 'org_name',
    defaultSort: 'ascending',
  },
  {
    sortable: true,
    title: 'Location',
    dataIndex: 'site_name',
  },
  {
    sortable: true,
    title: 'Asset',
    dataIndex: 'asset_name',
  },
  {
    sortable: true,
    title: 'Status',
    dataIndex: 'status',
    render: (value) => (
      <span className="unit-list-row-status-stat">
        <span className={value?.toString()?.toLowerCase()}>
          {value || '- -'}
        </span>
      </span>
    ),
  },
  {
    sortable: true,
    title: 'Run Time',
    dataIndex: 'runtime',
    render: (value) => (
      <div className="unit-list-row-runtime-stat">
        <span className={getDashboardRuntimeStatColor(value)}>{value ? `${value} %` : '- -'}</span>
      </div>
    ),
  },
  {
    sortable: true,
    title: 'Driver',
    dataIndex: 'driver',
  },
  {
    sortable: true,
    title: 'Last Synced',
    dataIndex: 'last_synced',
    render: (value) => (
      <span className="unit-list-row-synced-stat">{getFormattedElapsedTime(value) || '- -'}</span>
    ),
  },
];

export const assetCardListSortOptions = [{
  value: assetFieldNames.ORG_NAME,
  label: 'Alphabetical (Customer)',
}, {
  value: assetFieldNames.SITE_NAME,
  label: 'Alphabetical (Location)',
}, {
  value: assetFieldNames.ASSET_NAME,
  label: 'Numerical (Asset)',
}, {
  value: assetFieldNames.RUNTIME,
  label: 'Numerical (MA)',
}];

export const DEFAULT_AREA_ID = -1;
