import React from 'react';

export interface IEmissionsEquation {
  mathFunction?: React.ReactElement;
  description: string | React.ReactElement;
}

export const donutChartColors = [
  '#fac166',
  '#f7ac7c',
  '#f4777c',
  '#ee66bb',
  '#b572ca',
  '#9066bd',
  '#6697e5',
  '#6dd2db',
  '#66e077',
  '#fdf466',
];

export interface IEmissionsDetails {
  total: number;
  ethane: number;
  hexane: number;
  butane: number;
  iButane: number;
  methane: number;
  propane: number;
  pentane: number;
  iPentane: number;
  nitrogen: number;
  carbonDioxide: number;
}

export interface IFlaredDetails {
  total: number;
  btu: IEmissionsDetails;
  ngl: IEmissionsDetails;
  co2e: IEmissionsDetails;
}

export interface IEmissionData {
  volume: number;
  endDate: string;
  startDate: string;
  methaneVented: number;
  driveEmissions: number;
  flared: IFlaredDetails;
}

export interface IGasAnalysisData {
  id: string;
  parentId: string;
  version: number;
  createdAt: string;
  createdBy: string;
  updatedBy: string;
  deleted: boolean;
  lastAct: string;
  operatingAreaId: number;
  siteId: string;
  assetId: string;
  applicationType: string;
  gasMolPercents: IGasMolPercentData;
}

export interface IGasMolPercentData {
  carbonDioxidePct: number;
  nitrogenPct: number;
  methanePct: number;
  ethanePct: number;
  propanePct: number;
  butanePct: number;
  iButanePct: number;
  pentanePct: number;
  iPentanePct: number;
  hexanePct: number;
}
