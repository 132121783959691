import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getNavLinks } from 'shared/constants/navItems';
import { selectPermissions } from 'shared/reducers/permissionsSlice';
import { hasCarbonCalcAccess, isUserExternal } from 'shared/helpers/permissionHelpers';

import Header from './Header';
import SideNav from './SideNav';

import './SideNav.scss';

const canViewNewEmissionsCalculator = (
  process.env.REACT_APP_BUILD_ENV !== 'production'
  || process.env.REACT_APP_NEW_CALC === 'view'
);

const AppNavigation = ({
  openProfileModal,
  user,
  canAccessReports,
  preferences,
}) => {
  const { pathname } = useLocation();
  const { groups } = useSelector(selectPermissions);

  const hasCccAccess = hasCarbonCalcAccess(groups);
  const external_user = isUserExternal(user?.email);
  const hasNewEmissionAccess = canViewNewEmissionsCalculator && (!external_user || hasCccAccess);
  const currentNavLinks = getNavLinks(preferences?.homepage, hasNewEmissionAccess);

  const [navLinks, setNavLinks] = useState(currentNavLinks);

  const [activeLink, setActiveLink] = useState({
    path: pathname,
    key: navLinks.find((link) => pathname === link.path)?.key,
  });

  useEffect(() => {
    const activeKey = navLinks.find((link) => `/${pathname.split('/').at(1)}` === link.path)?.key;

    setActiveLink({
      key: activeKey ?? 'Dashboard',
      path: activeKey === 'Dashboard' || !activeKey ? '/dashboard' : `/${pathname.split('/').at(1)}`,
    });
  }, [pathname, navLinks]);

  useEffect(() => {
    setNavLinks(getNavLinks(preferences?.homepage, hasNewEmissionAccess));
  }, [preferences]);

  return (
    <>
      <Header
        user={user}
        navLinks={navLinks}
        activeLink={activeLink}
        openProfileModal={openProfileModal}
      />
      <SideNav
        navLinks={navLinks}
        activeLink={activeLink}
        preferences={preferences}
        canAccessReports={canAccessReports}
      />
    </>
  );
};

export default AppNavigation;
