import React from 'react';
import { Skeleton } from 'antd';

import FluxTooltip from 'shared/components/FluxTooltip';
import { tagTitleToTooltipMap } from 'shared/constants/graphingAndStatisticsConstants';

import { getUnitStatColor } from 'dashboard/helpers/dashboardHelpers';

import './UnitStat.scss';

const StatComponent = ({
  uom,
  stat,
  label,
  showTooltip,
}) => {
  const runtimeStatColor = getUnitStatColor(label, stat);

  const statValue = stat?.value || '_';
  const rtpStatValue = `${statValue} %`;

  const displayedStatValue = label === 'RTP'
    ? rtpStatValue
    : statValue;

  return (
    <div className="unit-stat">
      <div className="stat-value">
        <p style={{ color: runtimeStatColor }}>
          {displayedStatValue} {uom && <span>{uom}</span>}
        </p>
      </div>
      <div className="stat-info">
        <p className="stat-info-label">{label}</p>
        {showTooltip && (
          <FluxTooltip
            title={label}
            circleColor="#848383"
            description={tagTitleToTooltipMap[label]?.description}
          />
        )}
      </div>
    </div>
  );
};

const UnitStat = ({
  uom,
  stat,
  label,
  statLoading,
  showTooltip = false,
}) => (
  <section>
    {!statLoading && stat ? (
      <StatComponent
        uom={uom}
        stat={stat}
        label={label}
        showTooltip={showTooltip}
      />
    ) : (
      <Skeleton
        active
        width={300}
        paragraph={{
          rows: 1,
          width: '50%',
        }}
        className="unit-stat-skeleton"
      />
    )}
  </section>
);

export default UnitStat;
