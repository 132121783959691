import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { selectSession } from 'shared/reducers/sessionReducer';
import { fetchUnits } from 'shared/repositories/assetsRepository';

import EmissionsFleetView from 'emissions/components/EmissionsFleetView';

import EmissionsCalculatorTab from 'shared/components/EmissionsCalculatorTab';

import './EmissionsPage.scss';

export interface IDropdownOption {
  label: string;
  value: string | number;
}

const EmissionsPage: React.FC = () => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const currentUrl = `${pathname}${search}`;

  const session = useSelector(selectSession);

  const [units, setUnits] = useState<any>([]);
  const [unitsLoading, setUnitsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('Fleet Emissions');

  const getUnits = async (filters = []) => {
    setUnitsLoading(true);

    try {
      const response = await fetchUnits({ accessToken: session?.token, body: filters });

      if (!response.ok) {
        throw new Error(`Fetch failed with status: ${response.status}`);
      }

      const responseData = await response.json();
      const filteredResponseData = responseData?.filter((asset: any) => !asset.invalidated_at);

      setUnits(filteredResponseData);
    } catch (error) {
      console.error('Error fetching units:', error);
    } finally {
      setUnitsLoading(false);
    }
  };

  const toggleTab = () => {
    // if (currentUrl.startsWith('/emissions/fleet')) {
    //   navigate('/emissions/analysis');
    // } else {
    //   navigate('/emissions/fleet');
    // }
  };

  useEffect(() => {
    session?.token && !units?.length && getUnits();
  }, [session]);

  useEffect(() => {
    if (currentUrl.startsWith('/emissions/fleet')) {
      setCurrentTab('Fleet Emissions');
    }

    if (!currentUrl.startsWith('/emissions/fleet')) {
      navigate('/emissions/fleet');
    }
  }, [currentUrl]);

  return (
    <div className="emissions-page">
      {unitsLoading
        ? (
          <h2 className="emissions-loading-display-text">
            Loading emissions data...
          </h2>
        )
        : (
          <section className="emissions-page-content">
            <div className="emissions-page-header">
              <p className="emissions-page-header-text">
                Emissions Calculator
              </p>
              <p className="emissions-page-header-subtext">
                Metric Tons CO2e
              </p>
            </div>
            <div className="emissions-page-tabs">
              <EmissionsCalculatorTab
                label="Fleet Emissions"
                handleClick={toggleTab}
                active={currentTab === 'Fleet Emissions'}
                style={{
                  borderTopLeftRadius: '10px',
                  borderBottomLeftRadius: '10px',
                }}
              />
              <EmissionsCalculatorTab
                disabled
                active={false}
                handleClick={toggleTab}
                label="Manage Gas Analysis"
                style={{
                  borderTopRightRadius: '10px',
                  borderBottomRightRadius: '10px',
                  borderRight: 'solid 1px #D0D5DD',
                }}
              />
            </div>
            {currentTab === 'Fleet Emissions'
              ? <EmissionsFleetView units={units} />
              : <span />}
            {/* {currentTab === 'Manage Gas Analysis'
              ? <EmissionsGasAnalysisView units={units} />
              : <span />} */}
          </section>
        )}
    </div>
  );
};

export default EmissionsPage;
