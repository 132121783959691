import React, { useState } from 'react';

import FluxCustomDropdown from 'shared/components/FluxCustomDropdown';

import {
  CARBON_CAPTURE_FLARE_TITLE,
  CARBON_CAPTURE_VOLUME_TITLE,
  CARBON_CAPTURE_FLARE_TOOLTIP,
  CARBON_CAPTURE_VOLUME_TOOLTIP,
  CARBON_CAPTURE_EMISSIONS_TITLE,
} from 'single-asset/constants/carbonCaptureConstants';
import ResultsCard from 'single-asset/components/carbon-capture-calculator/modal/components/ResultsCard';
import DetailedResultsCard from 'single-asset/components/carbon-capture-calculator/modal/components/DetailedResultsCard';
import EmissionsResultsFunctionCard from 'single-asset/components/carbon-capture-calculator/modal/components/EmissionsResultFunctionCard';

import './EmissionsRow.scss';

const EmissionsRow = ({
  details,
  showEmissionsRow,
  handleEmissionsResultsToggle,
}) => {
  const volumeResult = details?.volume?.toFixed(4);
  const flareResult = `${(details?.flareEfficiency?.toFixed(4) ?? 0) * 100} %`;

  const [showEmissionsFunction, setShowEmissionsFunction] = useState(false);

  const handleEmissionsFunctionToggle = () => {
    setShowEmissionsFunction(!showEmissionsFunction);
  };

  return (
    <div className="carbon-capture-emissions-row">
      <FluxCustomDropdown
        isOpen={showEmissionsRow}
        header={CARBON_CAPTURE_EMISSIONS_TITLE}
        toggleDropdown={handleEmissionsResultsToggle}
      >
        {showEmissionsFunction ? (
          <EmissionsResultsFunctionCard
            currentCarbonDetails={details}
            tooltipText={CARBON_CAPTURE_FLARE_TOOLTIP}
            handleClose={handleEmissionsFunctionToggle}
          />
        ) : (
          <div className="emission-results-cards-container">
            <DetailedResultsCard
              currentCarbonDetails={details}
              showResultsFunction={handleEmissionsFunctionToggle}
            />
            <ResultsCard
              cardResult={flareResult}
              cardTitle={CARBON_CAPTURE_FLARE_TITLE}
              tooltipTitle={CARBON_CAPTURE_FLARE_TITLE}
              tooltipText={CARBON_CAPTURE_FLARE_TOOLTIP}
            />
            <ResultsCard
              cardResult={volumeResult}
              cardTitle={CARBON_CAPTURE_VOLUME_TITLE}
              tooltipTitle={CARBON_CAPTURE_VOLUME_TITLE}
              tooltipText={CARBON_CAPTURE_VOLUME_TOOLTIP}
            />
          </div>
        )}
      </FluxCustomDropdown>
    </div>
  );
};

export default EmissionsRow;
