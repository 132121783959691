import React from 'react';
import { IoAddOutline, IoCloseOutline } from 'react-icons/io5';

import MathCard from './MathCard';

import './MethaneBreakdownCard.scss';

const MethaneBreakdownCard = ({
  handleClose,
  currentCarbonDetails,
}) => (
  <section className="methane-breakdown-card">
    <div className="methane-breakdown-header">
      <div className="methane-breakdown-header-content">
        <p className="methane-total">{currentCarbonDetails.methaneCo2eMt.toFixed(4)}</p>
        <p className="methane-description">Methane Emissions Abated</p>
      </div>
      <div className="breakdown-close" onClick={() => handleClose()}>
        <IoCloseOutline size={24} color="#848383" />
      </div>
    </div>
    <div className="methane-breakdown-specifics">
      <div className="emissions-specifics-row">
        <MathCard
          bordered
          value={currentCarbonDetails?.volume?.toFixed(4)}
          label={<span>Volume of All Gas</span>}
        />
        <IoCloseOutline size={16} />
        <MathCard
          bordered
          value="1 - 0.98"
          label={<span>Flare Efficiency</span>}
        />
        <IoCloseOutline size={16} />
        <MathCard
          bordered
          value={0.01913}
          label={<span>Gas Density</span>}
        />
        <IoAddOutline size={16} />
      </div>
      <div className="emissions-specifics-row">
        <MathCard
          bordered
          value={currentCarbonDetails?.methanePct?.toFixed(4)}
          label={<span>Gas Mol %</span>}
        />
        <IoCloseOutline size={16} />
        <MathCard
          bordered
          value={currentCarbonDetails?.methaneGwp}
          label={<span>GWP</span>}
        />
      </div>
    </div>
  </section>
);

export default MethaneBreakdownCard;
