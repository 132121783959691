import {
  assetStatusTypes,
  okAssetStatusTypes,
  runningAssetStatusTypes,
  assetListStatusOptionValues,
} from 'shared/constants/assetConstants';

const { NOT_RUNNING_UNITS, RUNNING_UNITS } = assetListStatusOptionValues;

export const itemFilter = (filterString, keyList, item) => typeof filterString === 'string'
  && (
    filterString?.length === 0 || (
      keyList.some((key) => !!item?.[key] && item?.[key].toLocaleLowerCase().includes(filterString.toLocaleLowerCase())))
  );

export const isValidAssetAndTextMatch = (asset, filterText, filterFields) => asset?.invalidated_at === null && itemFilter(filterText, filterFields, asset);
export const isReviewAsset = (asset) => !runningAssetStatusTypes.includes(asset.status);
export const isRunningAsset = (asset) => runningAssetStatusTypes.includes(asset.status);

export const getFilteredAssetList = (assetList, selectedStatusFilter, filterText, filterFields) => (
  selectedStatusFilter === NOT_RUNNING_UNITS && assetList?.filter((asset) => isReviewAsset(asset) && isValidAssetAndTextMatch(asset, filterText, filterFields))
  || selectedStatusFilter === RUNNING_UNITS && assetList?.filter((asset) => isRunningAsset(asset) && isValidAssetAndTextMatch(asset, filterText, filterFields))
  || assetList?.filter((asset) => isValidAssetAndTextMatch(asset, filterText, filterFields))
);

export const getStatusClassAndText = (status) => (
  okAssetStatusTypes.includes(status) && ['ok', status]
  || [assetStatusTypes.FAULTED, assetStatusTypes.OFF].includes(status) && ['error', status]
  || ['warning', status]
);

export const getFormattedElapsedTime = (timestamp) => {
  const now = new Date();
  const date = new Date(timestamp);
  const seconds = Math.floor((now - date) / 1000);

  let interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return `${interval} years ago`;
  }

  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return `${interval} months ago`;
  }

  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return `${interval} days ago`;
  }

  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return `${interval} hours ago`;
  }

  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return `${interval} minutes ago`;
  }

  return `${Math.floor(seconds)} seconds ago`;
};

export const getConnectionPillVariant = (timestamp) => {
  const now = new Date();
  const date = new Date(timestamp);

  const isOlderThan48Hours = (now - date) > 48 * 60 * 60 * 1000;
  return isOlderThan48Hours ? 'red' : 'green';
};
