import { useState, useEffect } from 'react';
import { SearchBar } from '@flogistix/flo-ui';

import { useLocation, useNavigate } from 'react-router-dom';

const AssetListFormInput = ({
  units,
  disabled,
  removeFilter,
  savedFilters,
  setSavedFilters,
  handleInputText,
  currentInputValue,
  setCurrentInputValue,
}) => {
  const navigate = useNavigate();

  const { search, pathname } = useLocation();
  const currentUrl = `${pathname}${search}`;

  const [currentDropdownOptions, setCurrentDropdownOptions] = useState([]);

  const clearSearch = () => {
    setSavedFilters([]);
    navigate('/dashboard');
  };

  const getUniqueValuesWithLabel = (key, data, filter = '') => {
    const filteredData = data.filter((item) => {
      const value = item[key];
      if (typeof value === 'string' || typeof value === 'number') {
        return value.toString().toLowerCase().includes(filter?.toString()?.toLowerCase());
      }
      return false;
    });

    const uniqueValues = [...new Set(filteredData.map((item) => item[key]))];

    return uniqueValues.map((value) => {
      const item = data.find((entry) => entry[key] === value);
      let label;
      if (key === 'asset_name') {
        label = `Asset - ${item.asset_name}`;
      } else if (key === 'site_name') {
        label = item.site_name;
      } else if (key === 'org_name') {
        label = item.org_name;
      }
      return {
        label,
        type: key,
        value: item[key],
        org_id: item.org_id,
        site_id: item.site_id,
        asset_id: item.asset_id,
        org_name: item.org_name,
        device_id: item.device_id,
        site_name: item.site_name,
      };
    });
  };

  const appendUrls = (items) => items.map((item) => {
    let url;
    if (item.type === 'asset_name') {
      url = `${item.org_id}/${item.site_id}/${item.asset_id}/${item.device_id}`;
    } else if (item.type === 'site_name') {
      url = `?site=${item.site_id}`;
    } else if (item.type === 'org_name') {
      url = `?org=${item.org_id}`;
    }
    return { ...item, url };
  });

  const generateDropdowns = () => {
    const uniqueOrgNames = getUniqueValuesWithLabel('org_name', units, currentInputValue);
    const uniqueSiteNames = getUniqueValuesWithLabel('site_name', units, currentInputValue);
    const uniqueAssetNames = getUniqueValuesWithLabel('asset_name', units, currentInputValue);

    const aggregateDropdownData = [...uniqueAssetNames, ...uniqueOrgNames, ...uniqueSiteNames];

    const linkedOptions = appendUrls(aggregateDropdownData);
    const formattedOptions = linkedOptions?.map((entry) => ({
      value: entry?.url,
      label: entry?.label,
    }));

    setCurrentDropdownOptions(formattedOptions);
  };

  const handleOptionSelect = (option) => {
    let newUrl = option?.value;

    if (option?.label?.includes('Asset -')) {
      return navigate(`/asset/${newUrl}`);
    }

    if (newUrl.includes('org') && currentUrl.includes('org')) {
      const orgPortion = currentUrl.split('org=').at(-1).split('&').at(0);
      const newOrgPortion = newUrl.split('org=').at(-1).split('&').at(0);
      newUrl = currentUrl.replace(orgPortion, newOrgPortion);
      navigate(newUrl);
      return setCurrentInputValue('');
    }

    if (newUrl.includes('site') && currentUrl.includes('site')) {
      const sitePortion = currentUrl.split('site=').at(-1).split('&').at(0);
      const newSitePortion = newUrl.split('site=').at(-1).split('&').at(0);
      newUrl = currentUrl.replace(sitePortion, newSitePortion);
      navigate(newUrl);
      return setCurrentInputValue('');
    }

    if (currentUrl.includes('?')) {
      newUrl = newUrl.replace('?', '&');
      navigate(`${currentUrl}${newUrl}`);
      return setCurrentInputValue('');
    }

    navigate(newUrl);
    return setCurrentInputValue('');
  };

  useEffect(() => {
    if (units?.length && currentInputValue?.length > 2) {
      return generateDropdowns();
    }

    return setCurrentDropdownOptions([]);
  }, [units, currentInputValue]);

  return (
    <SearchBar
      dropShadow
      disabled={disabled}
      onClear={clearSearch}
      value={currentInputValue}
      onChange={handleInputText}
      onFilterRemove={removeFilter}
      onSelect={handleOptionSelect}
      dropdownClass="unit-list-search"
      dropdownOptions={currentDropdownOptions}
      placeholder="Search for asset, organization, site..."
      selectedFilters={savedFilters?.map((entry) => entry?.label)}
    />
  );
};

export default AssetListFormInput;
