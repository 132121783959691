import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import DraggableChartCard from 'single-asset/components/charts/edit-charts/DraggableChartCard';

const DraggableChartList = ({
  unitChartsLayout,
  setUnitChartsLayout,
}) => {
  const reorderCharts = (fromIndex, toIndex) => {
    const chartsCopy = [...unitChartsLayout];
    chartsCopy.splice(toIndex, 0, chartsCopy.splice(fromIndex, 1).at(0));
    return chartsCopy;
  };

  const removeChart = (indexOfItem) => {
    const chartsCopy = [...unitChartsLayout];
    chartsCopy.splice(indexOfItem, 1);
    setUnitChartsLayout(chartsCopy);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return false;
    const reorderedCharts = reorderCharts(
      result.source.index,
      result.destination.index,
    );
    return setUnitChartsLayout(reorderedCharts);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-charts">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {unitChartsLayout?.map(({ config: { series }, uuid }, index) => (
              <DraggableChartCard
                key={uuid}
                uuid={uuid}
                index={index}
                seriesData={series}
                removeChart={removeChart}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableChartList;
