import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  getOrgValue,
  openRedirect,
  getSiteValue,
  removeOrgParameter,
  removeSiteParameter,
  generateUnitViewRedirect,
  getAreaValue,
} from 'dashboard/helpers/dashboardHelpers';
import { selectSession } from 'shared/reducers/sessionReducer';
import { DEFAULT_AREA_ID } from 'dashboard/constants/dashboardConstants';
import DashboardHeader from 'dashboard/components/DashboardHeader';
import AssetList from 'dashboard/components/asset-display/AssetList';
import DashboardStats from 'dashboard/components/stats/DashboardStats';
import AssetListForm from 'dashboard/components/filtration/AssetListForm';
import DashboardNavigation from 'dashboard/components/navigation/DashboardNavigation';
import AssetFilterDropdowns from 'dashboard/components/filtration/AssetFilterDropdowns';

import './DashboardPage.scss';

const DashboardPage = ({
  user,
  units,
  getUnits,
  filterAreas,
  unitsLoading,
  statsLoading,
  dashboardStats,
  getDashboardStats,
  enterpriseObjects,
  dashboardAreaFilter,
  setDashboardAreaFilter,
  enterpriseObjectsLoading,
}) => {
  const session = useSelector(selectSession);
  const { search, pathname } = useLocation();
  const currentUrl = `${pathname}${search}`;

  const navigate = useNavigate();

  const [savedFilters, setSavedFilters] = useState([]);
  const [filteredUnits, setFilteredUnits] = useState(units);
  const [assetFilterText, setAssetFilterText] = useState('');
  const [selectedDisplayType, setSelectedDisplayType] = useState('Table');
  const [selectedStatusFilter, setSelectedStatusFilter] = useState('all-units');

  const handleFilterChange = (data) => {
    setAssetFilterText(data.filterText);
  };

  const handleDisplayTypeChange = () => {
    const newDisplayType = selectedDisplayType === 'Table'
      ? 'Cards'
      : 'Table';

    setSelectedDisplayType(newDisplayType);
  };

  const selectUnitView = (event, item) => {
    const link = generateLinkForTableRow(item);
    openRedirect(event, link);
  };

  const removeFilter = (stringToRemove) => {
    const filterToRemove = savedFilters?.find((entry) => entry?.label === stringToRemove);
    const newFilters = savedFilters?.filter((entry) => entry?.label !== stringToRemove);
    setSavedFilters(newFilters);

    const newUrl = filterToRemove.type === 'site'
      ? removeSiteParameter(currentUrl)
      : removeOrgParameter(currentUrl);

    navigate(newUrl);
  };

  const generateLinkForTableRow = (item) => {
    const relatedEnterpriseObject = enterpriseObjects
      ?.find((entry) => entry?.name?.toString() === item?.asset_name);

    const link = generateUnitViewRedirect(relatedEnterpriseObject);
    return link;
  };

  const updateAreaFilter = (filterId) => {
    setDashboardAreaFilter(filterId);

    if (filterId === -1) {
      navigate('/dashboard');
    } else {
      const url = new URL(currentUrl, window.location.origin);
      url.searchParams.set('area', filterId);
      navigate(url.pathname + url.search);
    }
  };

  const updateSearchFilters = () => {
    let updatedFilters = [...savedFilters];
    const orgFilter = getOrgValue(search);
    const siteFilter = getSiteValue(search);
    if (orgFilter && !savedFilters?.find((filter) => filter.type === 'org')?.label) {
      setAssetFilterText(orgFilter);
      const unitFoundWithOrg = units?.find((entry) => Number(entry?.org_id) === Number(orgFilter));
      const newSavedOrgFilter = { label: unitFoundWithOrg?.org_name, value: unitFoundWithOrg?.org_id?.toString(), type: 'org' };
      if (newSavedOrgFilter?.value) {
        updatedFilters = [
          newSavedOrgFilter,
          ...updatedFilters.filter((filter) => (
            (filter.type === 'org' && filter.label === newSavedOrgFilter.label)
            || (filter.type === 'site')
          )),
        ];
      }
    }

    if (siteFilter && siteFilter !== savedFilters?.find((filter) => filter.type === 'site')?.value) {
      const unitFoundWithSite = units?.find((entry) => Number(entry?.site_id) === Number(siteFilter));
      if (unitFoundWithSite) {
        setAssetFilterText(unitFoundWithSite?.site_name);
        const newSiteFilter = { label: unitFoundWithSite?.site_name, value: unitFoundWithSite?.site_id?.toString(), type: 'site' };
        if (newSiteFilter?.value) {
          updatedFilters = [
            newSiteFilter,
            ...updatedFilters.filter((filter) => (
              (filter.type === 'site' && filter.label === newSiteFilter.label)
              || (filter.type === 'org')
            )),
          ];
        }
      }
    }
    setSavedFilters(updatedFilters);
  };

  useEffect(() => {
    if (!search?.length) {
      setSavedFilters([]);
      setAssetFilterText('');
    }

    const orgFilter = getOrgValue(search);
    const siteFilter = getSiteValue(search);
    const areaFilter = getAreaValue(search) ?? DEFAULT_AREA_ID;

    updateSearchFilters();

    session?.token && getDashboardStats({
      areaFilter,
      orgFilter,
      siteFilter,
    });
    setDashboardAreaFilter(areaFilter);
  }, [session, search]);

  useEffect(() => {
    if (!units?.length) return;
    if (savedFilters?.length) return;
    updateSearchFilters();
  }, [units]);

  useEffect(() => {
    if (selectedStatusFilter === 'all-units') {
      setFilteredUnits(units);
    }

    if (selectedStatusFilter === 'running-units') {
      const newUnits = units?.filter((unit) => unit?.status === 'Running' || unit?.status === 'Auto' || unit?.status === 'Waiting to Auto Start');
      setFilteredUnits(newUnits);
    }

    if (selectedStatusFilter === 'not-running-units') {
      const newUnits = units?.filter((unit) => unit?.status !== 'Running' && unit?.status !== 'Auto' && unit?.status !== 'Waiting to Auto Start');
      setFilteredUnits(newUnits);
    }
  }, [units, selectedStatusFilter]);

  return (
    <div className="dashboard-page">
      <DashboardHeader user={user} />
      <div className="dashboard-page__content">
        <DashboardNavigation />
        <AssetFilterDropdowns
          getUnits={getUnits}
          filterAreas={filterAreas}
          updateAreaFilter={updateAreaFilter}
          dashboardAreaFilter={dashboardAreaFilter}
          selectedStatusFilter={selectedStatusFilter}
          setSelectedStatusFilter={setSelectedStatusFilter}
        />
        <DashboardStats
          statsLoading={statsLoading}
          dashboardStats={dashboardStats}
        />
        <AssetListForm
          units={filteredUnits}
          removeFilter={removeFilter}
          savedFilters={savedFilters}
          setSavedFilters={setSavedFilters}
          onFilterChange={handleFilterChange}
          setAssetFilterText={setAssetFilterText}
          onDisplayTypeChange={handleDisplayTypeChange}
          disabled={unitsLoading || enterpriseObjectsLoading}
        />
        <AssetList
          units={filteredUnits}
          savedFilters={savedFilters}
          filterText={assetFilterText}
          selectUnitView={selectUnitView}
          selectedDisplayType={selectedDisplayType}
          selectedStatusFilter={selectedStatusFilter}
          generateLinkForTableRow={generateLinkForTableRow}
          disabled={unitsLoading || enterpriseObjectsLoading}
        />
      </div>
    </div>
  );
};

export default DashboardPage;
