import { useState } from 'react';

import PillToggleButton from 'shared/components/PillToggleButton';

import AssetListFormInput from 'dashboard/components/filtration/AssetListFormInput';

import './AssetListForm.scss';

const AssetListForm = ({
  units,
  disabled,
  savedFilters,
  removeFilter,
  setSavedFilters,
  setAssetFilterText,
  onDisplayTypeChange,
}) => {
  const [currentInputValue, setCurrentInputValue] = useState('');

  const handleInputText = (e) => {
    setAssetFilterText(e?.target?.value?.toString());
    setCurrentInputValue(e?.target?.value?.toString());
  };

  return (
    <div className="asset-list-form">
      <AssetListFormInput
        units={units}
        disabled={disabled}
        savedFilters={savedFilters}
        removeFilter={removeFilter}
        handleInputText={handleInputText}
        setSavedFilters={setSavedFilters}
        currentInputValue={currentInputValue}
        setAssetFilterText={setAssetFilterText}
        setCurrentInputValue={setCurrentInputValue}
      />
      <PillToggleButton
        disabled={disabled}
        onToggle={onDisplayTypeChange}
        toggleNameList={['Card View', 'Table View']}
      />
    </div>
  );
};

export default AssetListForm;
