import React, { useEffect, useState } from 'react';
import { IoAddOutline, IoCloseOutline } from 'react-icons/io5';

import FluxSelect from 'shared/components/FluxSelect';
import { IEmissionData, IGasAnalysisData } from 'shared/constants/emissionsCalculatorConstants';

import { gasSelectOptions } from 'single-asset/constants/emissionsConstants';
import MathCard from 'single-asset/components/emissions-calculator/MathCard';

import './FlaredEmissionsBreakdown.scss';

interface FlaredEmissionsBreakdownProps {
  emissionsData: IEmissionData | null;
  gasAnalysis: IGasAnalysisData | null;
}

const FlaredEmissionsBreakdown: React.FC<FlaredEmissionsBreakdownProps> = ({ emissionsData, gasAnalysis }) => {
  const [gasMoleculeCount, setGasMoleculeCount] = useState(1);
  const [currentMolPercent, setCurrentMolPercent] = useState(0);
  const [currentGasOption, setCurrentGasOption] = useState(gasSelectOptions[0]);

  const totalGasVolume = emissionsData?.volume.toFixed(4);
  const formattedFlaredTotal = emissionsData?.flared?.co2e?.total?.toFixed(4) || '- -';

  const co2DensityConstant = 0.05262;
  const formattedFlareEfficiency = '1 - 0.98';

  useEffect(() => {
    switch (currentGasOption.value) {
      case 'Carbon Dioxide':
        setGasMoleculeCount(1);
        setCurrentMolPercent((gasAnalysis?.gasMolPercents?.carbonDioxidePct || 0) * 100);
        break;
      case 'Nitrogen':
        setGasMoleculeCount(0);
        setCurrentMolPercent((gasAnalysis?.gasMolPercents?.nitrogenPct || 0) * 100);
        break;
      case 'Ethane':
        setGasMoleculeCount(2);
        setCurrentMolPercent((gasAnalysis?.gasMolPercents?.ethanePct || 0) * 100);
        break;
      case 'Propane':
        setGasMoleculeCount(2);
        setCurrentMolPercent((gasAnalysis?.gasMolPercents?.propanePct || 0) * 100);
        break;
      case 'Methane':
        setGasMoleculeCount(1);
        setCurrentMolPercent((gasAnalysis?.gasMolPercents?.methanePct || 0) * 100);
        break;
      case 'Butane':
        setGasMoleculeCount(4);
        setCurrentMolPercent((gasAnalysis?.gasMolPercents?.butanePct || 0) * 100);
        break;
      case 'I-Butane':
        setGasMoleculeCount(4);
        setCurrentMolPercent((gasAnalysis?.gasMolPercents?.iButanePct || 0) * 100);
        break;
      case 'Pentane':
        setGasMoleculeCount(5);
        setCurrentMolPercent((gasAnalysis?.gasMolPercents?.pentanePct || 0) * 100);
        break;
      case 'I-Pentane':
        setGasMoleculeCount(5);
        setCurrentMolPercent((gasAnalysis?.gasMolPercents?.iPentanePct || 0) * 100);
        break;
      case 'Hexane':
        setGasMoleculeCount(6);
        setCurrentMolPercent((gasAnalysis?.gasMolPercents?.hexanePct || 0) * 100);
        break;
      default: break;
    }
  }, [currentGasOption]);

  return (
    <section className="flared-emission-breakdown-card">
      <div className="flared-emission-breakdown-header">
        <p className="emission-total">
          {formattedFlaredTotal}
        </p>
        <p className="emission-description">
          Flared Emissions Abated
        </p>
        <FluxSelect
          width="200px"
          showResetButton={false}
          options={gasSelectOptions}
          handleChange={setCurrentGasOption}
          defaultOption={gasSelectOptions[0]}
        />
      </div>
      <div className="flared-emission-breakdown-specifics">
        <div className="emissions-specifics-row">
          <MathCard
            bordered
            value={totalGasVolume}
            label="Volume of All Gas"
          />
          <IoCloseOutline size={16} />
          {currentGasOption.value !== 'Carbon Dioxide'
            && (
              <>
                <MathCard
                  bordered
                  label="Volume of All Gas"
                  value={formattedFlareEfficiency}
                />
                <IoCloseOutline size={16} />
              </>
            )}
          <MathCard
            bordered
            value={co2DensityConstant}
            label={<span>CO<sub>2</sub> Density</span>}
          />
          <IoAddOutline size={16} />
        </div>
        <div className="emissions-specifics-row">
          <MathCard
            bordered
            label={<span>Gas Mol %</span>}
            value={currentMolPercent?.toFixed(4)}
          />
          <IoCloseOutline size={16} />
          <MathCard
            bordered
            value={gasMoleculeCount}
            label={<span>Carbon Molecules</span>}
          />
        </div>
      </div>
    </section>
  );
};

export default FlaredEmissionsBreakdown;
