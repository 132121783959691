/* eslint-disable react/jsx-no-useless-fragment */
import LogRocket from 'logrocket';
import { useAuth0 } from '@auth0/auth0-react';
import { BreakpointProvider } from 'react-socks';
import { ThemeProvider } from 'styled-components';
import React, { useContext, useEffect, useState } from 'react';
import { ConfigProvider, notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import ProfileModal from 'shared/components/ProfileModal';
import AppNavigation from 'shared/components/AppNavigation';
import { selectSession } from 'shared/reducers/sessionReducer';
import { isUserExternal } from 'shared/helpers/permissionHelpers';
import { fetchUserGroups } from 'shared/actions/permissionsActions';
import { selectPermissions } from 'shared/reducers/permissionsSlice';
import { getAppClassNamesByTheme } from 'shared/helpers/themeHelpers';
import { REPORTING_GROUP } from 'shared/constants/permissionsConstants';
import { clearSession, setSession } from 'shared/actions/sessionActions';
import { selectNotifications } from 'shared/reducers/notificationsSlice';
import { removeNotification } from 'shared/actions/notificationsActions';
import { selectOrganizations } from 'shared/reducers/organizationsSlice';
import { fetchUserOrganizations } from 'shared/actions/organizationsActions';

import DashboardPageContainer from 'dashboard/pages/DashboardPageContainer';

import AssetPageContainer from 'single-asset/pages/AssetPageContainer';

import ReportingPage from 'reporting/pages/ReportingPage';

import AccountSettingsPage from 'account-settings/pages/AccountSettingsPage';
import { fetchUserSubscriptions } from 'shared/actions/subscriptionsActions';

import EmissionsPage from 'emissions/pages/EmissionsPage';

import './App.scss';
import './index.scss';
import { ChartingContext } from 'context/ChartingContext';

const { REACT_APP_ENV } = process.env;

const { localStorage } = window;

const FLOGISTIX_BRAND_COLOR = '#F05921';

const App = () => {
  const {
    user,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  const dispatch = useDispatch();

  const session = useSelector(selectSession);
  const { organizations } = useSelector(selectOrganizations);
  const theme = useSelector((state) => state.resources.theme);
  const demoMode = useSelector((state) => state.resources.demo);
  const { shownNotification } = useSelector(selectNotifications);
  const themeStyles = useSelector((state) => state.resources.themeStyles);
  const { groups, fetchAttempts: groupFetchAttempts } = useSelector(selectPermissions);

  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [isPendoInitialized, setIsPendoInitialized] = useState(false);

  const openProfileModal = () => setProfileModalOpen(true);
  const closeProfileModal = () => setProfileModalOpen(false);

  const [api, contextHolder] = notification.useNotification();
  const appClassNames = getAppClassNamesByTheme({ themeStyles, demo: demoMode.demo });

  const { charting } = useContext(ChartingContext);

  const defaultRoutes = [
    {
      link: '/emissions',
      path: '/emissions/*',
      exact: true,
      title: 'Emissions',
      mainNav: true,
      navExact: false,
      component: React.useCallback(() => <EmissionsPage />, []),
    },
    {
      link: '/dashboard',
      path: '/dashboard/*',
      exact: true,
      title: 'Dashboard',
      mainNav: true,
      navExact: false,
      component: React.useCallback(() => user && (
        <DashboardPageContainer user={user} />
      ), [user]),
    },
    {
      title: 'Asset',
      path: '/asset/:org_id?/:site_id?/:asset_id?/:device_id?/*',
      link: '/asset/:org_id?/:site_id?/:asset_id?/:device_id?',
      mainNav: true,
      component: React.useCallback(() => user && (
        <AssetPageContainer user={user} />
      ), [user]),
    },
    {
      title: 'Account Settings',
      path: '/notifications',
      link: 'notifications',
      component: React.useCallback(() => session?.user && <AccountSettingsPage />, [session]),
    },
    {
      title: 'Reporting',
      path: '/reports/*',
      link: 'reports',
      icon: 'chart',
      component: ReportingPage,
    },
  ];

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      dispatch(clearSession());
      loginWithRedirect();
    } else if (isAuthenticated) {
      if (REACT_APP_ENV === 'production') {
        LogRocket.init('ky2tzm/mi_fe');
        LogRocket.identify(user.email);
      }
      (async () => {
        const token = await getAccessTokenSilently();
        dispatch(setSession({ user, isAuthenticated, token }));
      })();
    }
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    localStorage.setItem('theme', JSON.stringify(themeStyles));
  }, [themeStyles]);

  useEffect(() => {
    localStorage.setItem('demo', JSON.stringify(demoMode));
  }, [demoMode.demo]);

  useEffect(() => {
    session?.token && dispatch(fetchUserOrganizations());
    session?.token && dispatch(fetchUserSubscriptions());
    session?.token && !groupFetchAttempts && !groups.length && dispatch(fetchUserGroups());
  }, [session]);

  useEffect(() => {
    if (!isPendoInitialized && !organizations?.length && user) {
      const external_user = isUserExternal(user?.email);

      const multiOrganizationName = organizations?.length > 1
        ? 'Multiple Organizations'
        : organizations?.at(0)?.orgName;

      const orgName = external_user
        ? multiOrganizationName
        : 'Flogistix';

      window.pendo.initialize({
        visitor: {
          id: user?.email,
          full_name: user?.name,
        },
        account: {
          id: orgName,
        },
      });

      setIsPendoInitialized(true);
    }
  }, [organizations, user]);

  useEffect(() => {
    if (shownNotification) {
      const { type, message, duration = '3' } = shownNotification;
      const notificationProps = {
        icon: <></>,
        closeIcon: null,
        onClose: () => dispatch(removeNotification()),
        placement: 'bottom',
        duration,
        message,
      };
      if (type === 'success') api.success(notificationProps);
      else if (type === 'error') api.error(notificationProps);
      else api.info(notificationProps);
    }
  }, [shownNotification]);

  useEffect(() => {
    if (user?.email && session?.token && charting.layoutFetchAttempts === 0) {
      charting.fetchLayouts(user.email, session.token);
    }
  }, [user, session]);

  return (
    <div className={appClassNames}>
      <BreakpointProvider>
        <ThemeProvider theme={theme}>
          <ConfigProvider
            theme={{
              token: {
                fontFamily: 'Inter',
                colorPrimary: FLOGISTIX_BRAND_COLOR,
              },
            }}
          >
            <AppNavigation
              user={user}
              openProfileModal={openProfileModal}
              canAccessReports={groups.includes(REPORTING_GROUP)}
            />
            <div>
              <Routes>
                {defaultRoutes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    element={(
                      <route.component
                        user={user}
                        isAuthenticated={isAuthenticated}
                      />
                    )}
                  />
                ))}
                {isAuthenticated
                  && (
                    <Route
                      path="/"
                      element={(
                        <Navigate
                          replace
                          to="dashboard"
                        />
                      )}
                    />
                  )}
              </Routes>
            </div>
            <ProfileModal
              user={user}
              isOpen={profileModalOpen}
              closeModal={closeProfileModal}
            />
            {contextHolder}
          </ConfigProvider>
        </ThemeProvider>
      </BreakpointProvider>
    </div>
  );
};

export default App;
