/* eslint-disable no-unused-vars */
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import FluxSelect from 'shared/components/FluxSelect';

import {
  timeAccruedOptions,
  defaultCarbonDetailRangeOption,
  CARBON_CAPTURE_TONS_DESCRIPTION,
} from 'single-asset/constants/carbonCaptureConstants';
import { selectAsset } from 'single-asset/reducers/assetReducer';
import EmissionsRow from 'single-asset/components/carbon-capture-calculator/modal/components/EmissionsRow';
import VaporCompositionRow from 'single-asset/components/carbon-capture-calculator/modal/components/VaporCompositionRow';
import CarbonCalculatorPill from 'single-asset/components/carbon-capture-calculator/modal/components/CarbonCalculatorPill';
import EquationBreakdownRow from 'single-asset/components/carbon-capture-calculator/modal/components/EquationBreakdownRow';

import './CarbonCaptureModal.scss';

const CarbonCaptureModal = ({
  isOpen,
  closeModal,
  getEmissionsData,
  emissionDetailsPending,
  defaultEmissionsDetails,
  currentCarbonCaptureTime,
  setCurrentCarbonCaptureTime,
}) => {
  const asset = useSelector(selectAsset);

  const [showEmissionsRow, setShowEmissionsRow] = useState(true);
  const [showVaporCompositionRow, setShowVaporCompositionRow] = useState(false);
  const [showEquationBreakdownRow, setShowEquationBreakdownRow] = useState(false);
  const [selectedCarbonDetails, setSelectedCarbonDetails] = useState(null);
  const [selectedDetailRange, setSelectedDetailRange] = useState(currentCarbonCaptureTime || defaultCarbonDetailRangeOption.value);

  const totalAbatedResult = selectedCarbonDetails?.netAbated?.toFixed(4);

  const handleEmissionsResultsToggle = () => {
    setShowEquationBreakdownRow(false);
    setShowVaporCompositionRow(false);
    setShowEmissionsRow(!showEmissionsRow);
  };

  const handleVaporCompositionToggle = () => {
    setShowEmissionsRow(false);
    setShowEquationBreakdownRow(false);
    setShowVaporCompositionRow(!showVaporCompositionRow);
  };

  const handleEquationBreakdownToggle = () => {
    setShowEmissionsRow(false);
    setShowVaporCompositionRow(false);
    setShowEquationBreakdownRow(!showEquationBreakdownRow);
  };

  const handleClose = () => {
    closeModal();
  };

  const handleDetailRangeChange = (option) => {
    option?.value === ('28 DAYS')
      ? setCurrentCarbonCaptureTime(`Last ${option?.label}`)
      : setCurrentCarbonCaptureTime(option?.label);

    setSelectedDetailRange(option?.value);
    getEmissionsData(option?.value);
  };

  useEffect(() => {
    defaultEmissionsDetails && setSelectedCarbonDetails(defaultEmissionsDetails);
  }, [defaultEmissionsDetails]);

  return (
    <Modal
      footer={[]}
      open={isOpen}
      maskClosable={false}
      onCancel={() => handleClose()}
      className="carbon-capture-modal"
      wrapClassName="carbon-capture-modal-dialog"
    >
      <div className="carbon-capture-modal-content">
        <section className="carbon-capture-modal-header">
          <div className="carbon-capture-modal-header-pills">
            <CarbonCalculatorPill
              label={<span>Asset {asset?.asset_name}</span>}
            />
            <CarbonCalculatorPill
              label={(
                <span>
                  {asset?.driver === 'GED'
                    ? 'Gas Engine Drive'
                    : 'Electric Engine Drive'}
                </span>
              )}
            />
          </div>
          <div className="carbon-capture-modal-header-total-container">
            <p className="carbon-capture-modal-header-total">
              {totalAbatedResult}
            </p>
            <p className="carbon-capture-modal-header-description">
              {CARBON_CAPTURE_TONS_DESCRIPTION}
            </p>
          </div>
          <div className="carbon-capture-modal-header-time-container">
            <FluxSelect
              width="160px"
              showResetButton={false}
              options={timeAccruedOptions}
              showLoading={emissionDetailsPending}
              handleChange={handleDetailRangeChange}
              defaultOption={defaultCarbonDetailRangeOption}
            />
          </div>
        </section>
        <section className="carbon-capture-modal-body">
          <EmissionsRow
            details={selectedCarbonDetails}
            showEmissionsRow={showEmissionsRow}
            handleEmissionsResultsToggle={handleEmissionsResultsToggle}
          />
          <VaporCompositionRow
            details={selectedCarbonDetails}
            showVaporCompositionRow={showVaporCompositionRow}
            handleVaporCompositionToggle={handleVaporCompositionToggle}
          />
          <EquationBreakdownRow
            methaneGwp={selectedCarbonDetails?.methaneGwp}
            showEquationBreakdownRow={showEquationBreakdownRow}
            handleEquationBreakdownToggle={handleEquationBreakdownToggle}
          />
        </section>
      </div>
    </Modal>
  );
};

export default CarbonCaptureModal;
