export const LOADING_TELEMETRY_STATS_MESSAGE = 'loading telemetry stats';

export const singleAssetRouteNames = {
  TELEMETRY: '',
  GAS_LIFT: 'gas_lift',
  EVENTS: 'events',
  WORK_ORDERS: 'work_orders',
  EMISSIONS_CALC: 'emissions_calculator',
  DIGITAL_TWIN: 'digital_twin',
  MORE_INFORMATION: 'more_information',
  INFORMATION: 'information',
  CONFIGURATION: 'configuration',
  TELEMETRY_REWRITE: 'telemetry_rewrite',
};

export const singleAssetRoutePaths = {
  GAS_LIFT: `${singleAssetRouteNames.GAS_LIFT}`,
  EVENTS: `${singleAssetRouteNames.EVENTS}`,
  WORK_ORDERS: `${singleAssetRouteNames.WORK_ORDERS}`,
  DIGITAL_TWIN: `${singleAssetRouteNames.DIGITAL_TWIN}`,
  EMISSIONS_CALC: `${singleAssetRouteNames.EMISSIONS_CALC}`,
  MORE_INFORMATION: `${singleAssetRouteNames.MORE_INFORMATION}`,
  INFORMATION: `${singleAssetRouteNames.INFORMATION}`,
  CONFIGURATION: `${singleAssetRouteNames.CONFIGURATION}`,
  TELEMETRY_REWRITE: `${singleAssetRouteNames.TELEMETRY_REWRITE}`,
};

export const defaultStatsState = {
  isPending: false,
  statsList: [],
  failedResultList: [],
};

export const defaultSeriesState = {
  isPending: false,
  seriesList: [],
  failedResultList: [],
  dates: null,
  modifyError: null,
  refetchCharts: false,
};

export const MM_DD_YYYY_DATE_FORMATTER = 'MM/DD/YYYY';
export const DEFAULT_DATE_FORMAT = 'MM-dd-yyyy hh:mm:ss a';
export const MOMENT_DATE_FORMAT__MOMENT_IS_DEPRECATED = 'YYYY-MM-DD HH:mm:ss';
export const MONTH_DAY_YEAR = 'MMM DD, YYYY';
export const CHART_TIME_FORMAT = 'h:mm a';

export const GROUP_IDS_WITH_IP_EDIT_ACCESS = [
  'decoder-customer-advanced-tech',
  'decoder-flogistix-programmer',
  'field-service-tech',
];

export const assetDrivers = {
  GED: 'GED',
  ENGINE: 'Engine',
  ELEC: 'ELEC',
  MOTOR: 'Motor',
};

export const coordinateTags = {
  lat: {
    key: 'lat',
    tag_id: 30,
    agg_func: 'last_value',
    round: false,
  },
  lon: {
    key: 'lon',
    tag_id: 30,
    agg_func: 'last_value',
    round: false,
  },
};
