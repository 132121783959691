/* eslint-disable consistent-return */
const ENV_URL = process.env.REACT_APP_BUILD_ENV === 'production'
  ? 'https://'
  : 'https://dev-';

const QUERIES_URL = `${ENV_URL}queries.api.axil.ai`;

const RTP_UTL = '/v1/axil/units/rtp';
const OFF_URL = '/v1/axil/units/off';
const FAULTED_URL = '/v1/axil/units/faulted';
const RUNNING_URL = '/v1/axil/units/running';
const NOT_RUNNING_URL = '/v1/axil/units/not-running';

const buildFilters = (filters) => {
  const { areaFilter, orgFilter, siteFilter } = filters;
  let filterString = '';
  if (areaFilter !== -1 || orgFilter || siteFilter) {
    filterString = '{';
    if (areaFilter !== -1) {
      filterString += `"area":[${areaFilter}]`;
    }
    if (orgFilter) {
      filterString += `${filterString.includes('area') ? ',' : ''}"org":[${orgFilter}]`;
    }
    if (siteFilter) {
      filterString += `${filterString.includes('area') || filterString.includes('org') ? ',' : ''}"site":[${parseInt(siteFilter, 10)}]`;
    }
    filterString += '}';
  }
  return filterString.length ? `?filters=${filterString}` : '';
};

export const fetchRtpStat = async ({ accessToken, filters }) => {
  const filterString = buildFilters(filters);
  const FORMATTED_URL = `${QUERIES_URL}${RTP_UTL}`;
  const FILTERED_URL = `${FORMATTED_URL}${filterString}`;

  try {
    const response = await fetch(FILTERED_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching RTP stat: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.log('Error fetching RTP stat.');
  }
};

export const fetchOffStat = async ({ accessToken, filters }) => {
  const filterString = buildFilters(filters);
  const FORMATTED_URL = `${QUERIES_URL}${OFF_URL}`;
  const FILTERED_URL = `${FORMATTED_URL}${filterString}`;

  try {
    const response = await fetch(FILTERED_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching RTP stat: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.log('Error fetching Off stat.');
  }
};

export const fetchRunningStat = async ({ accessToken, filters }) => {
  const filterString = buildFilters(filters);
  const FORMATTED_URL = `${QUERIES_URL}${RUNNING_URL}`;
  const FILTERED_URL = `${FORMATTED_URL}${filterString}`;

  try {
    const response = await fetch(FILTERED_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching RTP stat: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.log('Error fetching Running stat.');
  }
};

export const fetchFaultedStat = async ({ accessToken, filters }) => {
  const filterString = buildFilters(filters);
  const FORMATTED_URL = `${QUERIES_URL}${FAULTED_URL}`;
  const FILTERED_URL = `${FORMATTED_URL}${filterString}`;

  try {
    const response = await fetch(FILTERED_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching Faulted stat: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.log('Error fetching Faulted stat.');
  }
};

export const fetchNotRunningStat = async ({ accessToken, filters }) => {
  const filterString = buildFilters(filters);
  const FORMATTED_URL = `${QUERIES_URL}${NOT_RUNNING_URL}`;
  const FILTERED_URL = `${FORMATTED_URL}${filterString}`;

  try {
    const response = await fetch(FILTERED_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching Not Running stat: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.log('Error fetching Not Running stat.');
  }
};
