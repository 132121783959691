import React from 'react';

import { MISSING_FIELD_VALUE_PLACEHOLDER } from 'shared/constants/altitudeConstants';

import './ChartStatCard.scss';

const ChartStatCard = ({
  color,
  title,
  statValue,
  aggregateData,
  displayGridView,
  setCurrentStatDetail,
}) => {
  const currentStatSeriesDataArray = Object.entries(aggregateData)
    ?.filter((entry) => entry?.[0] === title);

  const currentStatSeriesValues = currentStatSeriesDataArray[0];
  const currentStatSeriesMin = currentStatSeriesValues?.[1]?.min;
  const currentStatSeriesMax = currentStatSeriesValues?.[1]?.max;
  const currentStatSeriesMean = currentStatSeriesValues?.[1]?.mean;

  const openRow = () => {
    const currentClickedStat = {
      color,
      label: title,
      value: statValue,
    };

    setCurrentStatDetail(currentClickedStat);
  };

  const calculateSummaryValue = (chartValue) => {
    if (typeof chartValue === 'object' && chartValue !== null) {
      return Number(chartValue.y).toFixed(2) ?? MISSING_FIELD_VALUE_PLACEHOLDER;
    }
    return chartValue ?? MISSING_FIELD_VALUE_PLACEHOLDER;
  };

  return (
    <div
      onClick={displayGridView ? openRow : console.log()}
      className={displayGridView ? 'chart-stat-card-grid' : 'chart-stat-card'}
    >
      <section
        className="stat-card-section"
        style={{ width: displayGridView ? '100%' : '50%' }}
      >
        <div className="stat-card-title-container">
          <h6>{title}</h6>
          <span
            className="stat-color"
            style={{ backgroundColor: color ?? '#848383' }}
          />
        </div>
        <section className="stat-card-info-container">
          <div className="stat">{calculateSummaryValue(statValue)}</div>
        </section>
      </section>
      {!displayGridView && (
        <section className="detailed-stats">
          <div className="detailed-stat">
            <span>{calculateSummaryValue(currentStatSeriesMin)}</span>
            <p className="detailed-stat-label">min</p>
          </div>
          <div className="detailed-stat">
            <span>{calculateSummaryValue(currentStatSeriesMax)}</span>
            <p className="detailed-stat-label">max</p>
          </div>
          <div className="detailed-stat">
            <span>{calculateSummaryValue(currentStatSeriesMean)}</span>
            <p className="detailed-stat-label">avg</p>
          </div>
        </section>
      )}
    </div>
  );
};

export default ChartStatCard;
