import React from 'react';

import './VaporCompositionBreakdownTable.scss';

export interface VaporCompositionBreakdownTableColumn {
  key: string;
  title: React.ReactNode;
}

export interface VaporCompositionBreakdownTableRow {
  key: string | number;
  btu: string | number;
  ngl: string | number;
  title: string | number;
  label: string | number;
  flared: string | number;
  percent: string | number;
}

interface VaporCompositionBreakdownTableProps {
  dataRows: VaporCompositionBreakdownTableRow[];
  columns: VaporCompositionBreakdownTableColumn[];
}

const VaporCompositionBreakdownTable: React.FC<VaporCompositionBreakdownTableProps> = ({
  columns,
  dataRows,
}) => (
  <div className="vapor-composition-breakdown-table">
    <section className="table-header">
      {columns?.map((entry) => (
        <div
          key={entry?.key}
          className="table-header-cell"
        >
          <span className="table-header-cell-text">
            {entry?.title}
          </span>
        </div>
      ))}
    </section>
    <section className="table-body">
      {dataRows?.map((entry) => (
        <div
          key={entry.key}
          className="table-body-row"
        >
          <div className="table-body-row-cell">
            <p>{entry.label || '- -'}</p>
          </div>
          <div className="table-body-row-cell">
            <p>{entry.percent || '- -'}</p>
          </div>
          <div className="table-body-row-cell">
            <p>{entry.flared || '- -'}</p>
          </div>
          <div className="table-body-row-cell">
            <p>{entry.btu && Number(entry.btu)?.toFixed(2)?.toString() || '- -'}</p>
          </div>
          <div className="table-body-row-cell">
            <p>{entry.ngl && Number(entry.ngl)?.toFixed(2)?.toString() || '- -'}</p>
          </div>
        </div>
      ))}
    </section>
  </div>
);
export default VaporCompositionBreakdownTable;
