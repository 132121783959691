import { IoTimeOutline } from 'react-icons/io5';

import {
  DND_OPTIONS,
  DELIVERY_METHOD_TOGGLE_TEXTS,
  NOTIFICATIONS_ASSET_FILTER_PLACEHOLDER,
} from 'account-settings/constants/accountSettingsConstants';

import FluxDropdown from 'shared/components/FluxDropdown';
import ToggleButton from 'shared/components/ToggleButton';
import FluxAutoComplete from 'shared/components/FluxAutoComplete';

const NotificationSettings = ({
  dndSettings,
  preferences,
  activeSection,
  clearDndSettings,
  setAssetFilterText,
  handleDndStopChange,
  handleDndStartChange,
  updateReceivingTexts,
  updateReceivingEmails,
}) => (
  <div className="filter-section">
    <div className="text-and-toggle">
      <div className="toggle-group">
        <ToggleButton
          disabled={false}
          showTooltip={false}
          onClick={updateReceivingEmails}
          checked={preferences.send_email}
          title={DELIVERY_METHOD_TOGGLE_TEXTS.email}
        />
        <ToggleButton
          disabled={false}
          showTooltip={false}
          checked={preferences.send_sms}
          onClick={updateReceivingTexts}
          title={DELIVERY_METHOD_TOGGLE_TEXTS.text}
        />
      </div>
      <FluxAutoComplete
        placeholder={NOTIFICATIONS_ASSET_FILTER_PLACEHOLDER}
        onChange={(input) => setAssetFilterText(input.toLowerCase())}
      />
    </div>
    {activeSection === 'My Notifications' && (
      <div className="dnd-section">
        <div className="dnd-label-container">
          <p>Do Not Disturb</p>
          {preferences?.dnd_start || preferences?.dnd_end
            ? (
              <div
                className="clear-button"
                onClick={() => clearDndSettings()}
              >
                Clear Times
              </div>
            )
            : <div />}
        </div>
        <div className="dnd-pickers">
          <FluxDropdown
            label="Start Time"
            options={DND_OPTIONS}
            popupPlacement="topLeft"
            placeholder="Select Start Time"
            popupClasses="flexed-dnd-start"
            icon={<IoTimeOutline size={28} />}
            handleChange={handleDndStartChange}
            value={
              DND_OPTIONS.find((option) => option.value === dndSettings?.dnd_start)?.label
              || DND_OPTIONS.find((option) => option.value === preferences?.dnd_start)?.label
            }
          />
          <FluxDropdown
            label="End Time"
            options={DND_OPTIONS}
            popupPlacement="topLeft"
            placeholder="Select End Time"
            popupClasses="flexed-dnd-stop"
            icon={<IoTimeOutline size={28} />}
            handleChange={handleDndStopChange}
            value={
              DND_OPTIONS.find((option) => option.value === dndSettings?.dnd_stop)?.label
              || DND_OPTIONS.find((option) => option.value === preferences?.dnd_stop)?.label
            }
          />
        </div>
      </div>
    )}
  </div>
);

export default NotificationSettings;
