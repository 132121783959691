import React from 'react';
import { v4 as uuid } from 'uuid';

import ChartCard from 'single-asset/components/charts/ChartCard';

import './ChartsList.scss';

const ChartsList = ({
  name,
  getCharts,
  chartsData,
  getChartsByDate,
  displayGridView,
  unitChartConfig,
  chartLayout,
}) => (
  <div className="charts-list-container">
    {chartsData?.map((chartData, index) => (
      <ChartCard
        name={name}
        key={uuid()}
        index={index}
        getCharts={getCharts}
        chartData={chartData}
        unitChartConfig={unitChartConfig}
        displayGridView={displayGridView}
        getChartsByDate={getChartsByDate}
        chartLayout={chartLayout.charts.at(index)}
        width={displayGridView ? '48%' : '98.5%'}
      />
    ))}
  </div>
);
export default ChartsList;
