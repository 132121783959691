import { useState } from 'react';

import ChartForm from 'single-asset/components/charts/edit-charts/ChartForm';
import ChartsContentSection from 'single-asset/components/charts/ChartsContentSection';

import './ChartsContainer.scss';

const ChartsContainer = ({
  asset,
  charts,
  getCharts,
  chartDates,
  chartsRange,
  legacyLayout,
  setChartDates,
  customMetrics,
  setChartsRange,
  unitChartConfig,
  unitChartsLayout,
  updateChartsLayout,
  setUnitChartsLayout,
  chartLayout,
}) => {
  const [isEditingCharts, setIsEditingCharts] = useState(false);
  const [displayGridView, setDisplayGridView] = useState(false);

  const resetChartsLayout = () => setUnitChartsLayout(unitChartsLayout);

  return (
    <div className="charts-container">
      <ChartForm
        asset={asset}
        getCharts={getCharts}
        chartDates={chartDates}
        chartsRange={chartsRange}
        legacyLayout={legacyLayout}
        setChartDates={setChartDates}
        customMetrics={customMetrics}
        setChartsRange={setChartsRange}
        isEditingCharts={isEditingCharts}
        displayGridView={displayGridView}
        unitChartConfig={unitChartConfig}
        unitChartsLayout={unitChartsLayout}
        resetChartsLayout={resetChartsLayout}
        setDisplayGridView={setDisplayGridView}
        setIsEditingCharts={setIsEditingCharts}
        updateChartsLayout={updateChartsLayout}
      />
      <ChartsContentSection
        chartsData={charts}
        getCharts={getCharts}
        displayGridView={displayGridView}
        isEditingCharts={isEditingCharts}
        unitChartConfig={unitChartConfig}
        unitChartsLayout={unitChartsLayout}
        resetChartsLayout={resetChartsLayout}
        setUnitChartsLayout={setUnitChartsLayout}
        chartLayout={chartLayout}
      />
    </div>
  );
};

export default ChartsContainer;
