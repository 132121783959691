export const TELEMETRY_DASHBOARD_ID = 5;

export const NEW_CHART_DROPDOWN_SEARCH_PLACEHOLDER = 'Search "Engine Hour Meter"';
export const NEW_CHART_DROPDOWN_HEADER = 'Select metrics for new chart';

export const AGGREGATION_VALUES = {
  mean: ['y'],
  max: ['y'],
  min: ['y'],
};

export const NON_EVENT_METRIC_MAP = {
  epoch_ms: 'x',
  val: 'y',
  uom: 'uom',
};

export const EVENT_METRIC_MAP = {
  epoch_ms: 'x',
  val: 'msg',
};

export const CHART_METRIC_COLORS = [
  '#0A84FF',
  '#00C278',
  '#F05921',
];

export const DEFAULT_CHART_TYPE = 'line';

export const EDIT_CHART_LAYOUT_HELPER_TEXT = 'Remove any charts from your view or drag and drop to reorder';

export const MODIFY_CHART_ERROR = 'An error occurred modifying charting layout. Please try again.';

export const APPLY_TEMPLATE_TOOLTIP = {
  title: 'Apply to all Assets',
  description: 'Apply this current chart layout view to every Asset you have access to.',
  placement: 'bottom',
  arrow: false,
  tooltip: '',
};

export const TEMPLATE_CONFIRMATION_MODAL_TITLE = 'Are you sure you want to apply the current chart layout to every Asset of this type?';

export const STATUS_STAT_ID = 562;
export const FAULTED_STAT_ID = 881;
export const RUNTIME_STAT_ID = 915;
export const ELEVATION_STAT_ID = 1688;
export const ENGINE_HOURS_STAT_ID = 23;
export const ELECTRIC_SPEED_STAT_ID = 13;
export const DISCHARGE_TEMP_STAT_ID = 869;
export const APPLICATION_TYPE_STAT_ID = 34;
export const SUCTION_PRESSURE_STAT_ID = 564;
export const DISCHARGE_PRESSURE_STAT_ID = 565;
