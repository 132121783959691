import LiveCount from 'shared/components/LiveCount';
import FluxDatePicker from 'shared/components/FluxDatePicker';
import FluxAutoComplete from 'shared/components/FluxAutoComplete';

import { ASSET_EVENT_SEARCH_PLACEHOLDER_TEXT } from 'single-asset/constants/assetEventConstants';

import './AssetEventsSearchForm.scss';

const AssetEventsSearchForm = ({
  disabled,
  finalizedDates,
  setSearchString,
  handleClearDates,
  setFinalizedDates,
  formattedAssetEvents,
}) => {
  const assetEventCount = formattedAssetEvents?.length;

  return (
    <div className="asset-events-search-form">
      <FluxAutoComplete
        disabled={disabled}
        onChange={(input) => setSearchString(input)}
        placeholder={ASSET_EVENT_SEARCH_PLACEHOLDER_TEXT}
      />
      <section className="live-count-header">
        <LiveCount countType="Events" count={assetEventCount} />
        <div
          data-testid="events-date-picker"
          className="date-picker-container"
        >
          <FluxDatePicker
            finalizedDates={finalizedDates}
            clearFunction={handleClearDates}
            setFinalizedDates={setFinalizedDates}
          />
        </div>
      </section>
    </div>
  );
};

export default AssetEventsSearchForm;
