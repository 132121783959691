import { v4 as uuid } from 'uuid';

import UnitStat from 'single-asset/components/asset/UnitStat';

import './UnitStatList.scss';

const UnitStatList = ({ unitStats, statsLoading }) => {
  const rtpStat = unitStats?.find((entry) => entry?.name === 'RTP');
  const statusStat = unitStats?.find((entry) => entry?.name === 'Status');
  const faultsStat = unitStats?.find((entry) => entry?.name === 'Faulted');
  const elevationStat = unitStats?.find((entry) => entry?.name === 'Elevation');
  const electricHoursStat = unitStats?.find((entry) => entry?.name === 'Electric Hours');
  const electricSpeedStat = unitStats?.find((entry) => entry?.name === 'Electric Speed');
  const suctionPressureStat = unitStats?.find((entry) => entry?.name === 'Suction Pressure');
  const applicationTypeStat = unitStats?.find((entry) => entry?.name === 'Application Type');
  const dischargeTempStat = unitStats?.find((entry) => entry?.name === 'Discharge Temperature');
  const dischargePressureStat = unitStats?.find((entry) => entry?.name === 'Discharge Pressure');

  return (
    <section className="unit-stats-list-container">
      <div className="unit-stats-list">
        <UnitStat
          label="RTP"
          showTooltip
          key={rtpStat?.name}
          stat={rtpStat?.stat}
          statLoading={statsLoading}
        />
        <UnitStat
          label="Status"
          key={statusStat?.name}
          stat={statusStat?.stat}
          statLoading={statsLoading}
        />
        <UnitStat
          label="Faulted"
          key={faultsStat?.name}
          stat={faultsStat?.stat}
          statLoading={statsLoading}
        />
        <UnitStat
          label="Application Type"
          statLoading={statsLoading}
          key={applicationTypeStat?.name}
          stat={applicationTypeStat?.stat}
        />
        <UnitStat
          uom="°F"
          statLoading={statsLoading}
          label="Discharge Temperature"
          key={dischargeTempStat?.name}
          stat={dischargeTempStat?.stat}
        />
        <UnitStat
          uom="psi"
          label="Suction Pressure"
          statLoading={statsLoading}
          key={suctionPressureStat?.name}
          stat={suctionPressureStat?.stat}
        />
        <UnitStat
          uom="psi"
          key={uuid()}
          label="Discharge Pressure"
          statLoading={statsLoading}
          stat={dischargePressureStat?.stat}
        />
        <UnitStat
          label="Engine Hours"
          statLoading={statsLoading}
          key={electricHoursStat?.name}
          stat={electricHoursStat?.stat}
        />
        <UnitStat
          uom="rpm"
          label="Electric Speed"
          statLoading={statsLoading}
          key={electricSpeedStat?.name}
          stat={electricSpeedStat?.stat}
        />
        <UnitStat
          uom="ft"
          label="Elevation"
          key={elevationStat?.name}
          stat={elevationStat?.stat}
          statLoading={statsLoading}
        />
      </div>
    </section>
  );
};

export default UnitStatList;
