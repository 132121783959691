export const NOTIFICATION_COLUMNS = [
  {
    title: 'All',
    dataIndex: 'all',
  }, {
    title: 'Debug',
    dataIndex: 'debug',
  }, {
    title: 'Info',
    dataIndex: 'info',
  }, {
    title: 'Alarms',
    dataIndex: 'warning',
  }, {
    title: 'Faults',
    dataIndex: 'danger',
  }, {
    title: 'Running',
    dataIndex: 'success',
  },
];

export const headerStatTitles = {
  COMPANY: 'COMPANY',
  LEASE_NAME: 'LEASE NAME',
  AREA: 'AREA',
  UNIT_OWNER: 'UNIT OWNER',
  MECHANIC: 'MECHANIC',
  IP_ADDRESS: 'IP ADDRESS',
};

export const IP_PORT_OPTIONS = [
  {
    value: 9600,
    label: '9600',
  },
  {
    value: 502,
    label: '502',
  },
];

export const IP_COPY_SUCCESS_MESSAGE = 'IP Address has been copied to clipboard';
export const INVALID_IP_ERROR_MESSAGE = 'Invalid IP Address. Must be in format of "XX.XX.XX.XX"';

export const NOTIFICATIONS_ICON_TITLE = 'Asset Notifications';
export const NOTIFICATIONS_ICON_DESCRIPTION = 'Turn on notifications for this asset.';

export const UNIT_PROFILE_ICON_TITLE = 'Unit Profile';
export const UNIT_PROFILE_ICON_DESCRIPTION = "Links you directly to this asset's Unit Profile.";
