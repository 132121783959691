import { getOffsetDateArr } from 'shared/helpers/datePickerHelpers';
import { extendedDayJs } from 'shared/helpers/timeHelpers';

import {
  MM_DD_YYYY_DATE_FORMATTER,
  MOMENT_DATE_FORMAT__MOMENT_IS_DEPRECATED,
} from 'single-asset/constants/singleAssetConstants';

import {
  CUSTOMER_DECODER,
  FLOGISTIX_DECODER,
  CUSTOMER_ADVANCED_TECH,
  FLOGISTIX_ADVANCED_TECH,
  FLOGISTIX_FIELD_SERVICE_TECH,
} from 'shared/constants/permissionsConstants';

const now = new Date();

export const formatDateRangeAsStrings = (dateRange, formatter = MM_DD_YYYY_DATE_FORMATTER) => ([
  dateRange.at(0).format(formatter),
  dateRange.at(1).format(formatter),
]);

export const getDefaultDateRange = () => (getOffsetDateArr([
  extendedDayJs(now).subtract(1, 'days'),
  extendedDayJs(now),
]));

export const formatTimeStringsForCharts = (timeRange) => (
  timeRange?.map((time) => extendedDayJs(time).format())
);

export const formatXAxisPropertyToDatestring = (dataList, propertyName) => dataList?.map((data) => ({
  ...data,
  [propertyName]: extendedDayJs(data[propertyName]).format(MOMENT_DATE_FORMAT__MOMENT_IS_DEPRECATED),
}));

export const isValidIpAddress = (address) => !!/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi.test(address);

export const sharedRequestBody = (asset) => ({
  org_id: asset?.org_id,
  site_id: asset?.site_id,
  asset_id: asset?.asset_id,
  device_id: asset?.device_id,
});

export const checkIpEditPermissions = (groups) => {
  const requiredGroups = [
    CUSTOMER_DECODER,
    FLOGISTIX_DECODER,
    CUSTOMER_ADVANCED_TECH,
    FLOGISTIX_ADVANCED_TECH,
    FLOGISTIX_FIELD_SERVICE_TECH,
  ];

  return groups.some((group) => requiredGroups.includes(group));
};

export const generateUnitProfilesLink = (netsuiteId) => `https://unitprofile.flogistix.com/unitprofile/${netsuiteId}/view`;
