/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tabs, Tab } from '@flogistix/flo-ui';
import { selectSession } from 'shared/reducers/sessionReducer';
import { getUnitProfileInfo, getUnitProfileLink } from 'shared/repositories/moreInfoStatsRepository';
import { checkDigitalTwinExistence } from 'shared/repositories/assetsRepository';
import { getCarbonCaptureDetails } from 'shared/repositories/carbonCaptureRepository';
import { isUserExternal, hasCarbonCalcAccess } from 'shared/helpers/permissionHelpers';

import UnitHeader from 'single-asset/components/asset/UnitHeader';
import AssetRoutes from 'single-asset/components/asset/AssetRoutes';
import { selectPermissions } from 'shared/reducers/permissionsSlice';
import NotificationModal from 'single-asset/components/asset/NotificationModal';
import { generateUnitProfilesLink, sharedRequestBody } from 'single-asset/helpers/singleAssetHelpers';
import CarbonCaptureModal from 'single-asset/components/carbon-capture-calculator/modal/CarbonCaptureModal';
import { coordinateTags, singleAssetRouteNames } from 'single-asset/constants/singleAssetConstants';

import './AssetPage.scss';

const canViewNewEmissionsCalculator = (
  process.env.REACT_APP_BUILD_ENV !== 'production'
  || process.env.REACT_APP_NEW_CALC === 'view'
);

const AssetPage = ({
  user,
  asset,
  subscriptions,
  addIpCopyToast,
  hasEditIpAccess,
  getSubscriptions,
  enterpriseObject,
  fetchedSubscriptions,
  updateUserSubscriptions,
}) => {
  const currentSession = useSelector(selectSession);
  const { groups } = useSelector(selectPermissions);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const navPath = pathname.split('/').at(-1);
  const [showDataError, setShowDataError] = useState(false);
  const [digitalTwinExistence, setDigitalTwinExistence] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);

  const [emissionsDetails, setEmissionsDetails] = useState(null);
  const [carbonCaptureModalOpen, setCarbonCaptureModalOpen] = useState(false);
  const [emissionsDetailsPending, setEmissionsDetailsPending] = useState(false);
  const [currentCarbonCaptureTime, setCurrentCarbonCaptureTime] = useState('Last 28 Days');

  const [unitProfilesLink, setUnitProfilesLink] = useState(null);

  const [coordinates, setCoordinates] = useState(null);

  const hasCccAccess = hasCarbonCalcAccess(groups);
  const external_user = isUserExternal(user?.email);
  const hasNewEmissionAccess = canViewNewEmissionsCalculator && (!external_user || hasCccAccess);

  const openCarbonModal = () => {
    setCarbonCaptureModalOpen(true);
  };

  const closeCarbonModal = () => {
    setCarbonCaptureModalOpen(false);
  };

  const closeNotificationModal = () => setNotificationModalOpen(false);

  const getEmissionsData = async (calculationTime = 'last_28_days') => {
    setEmissionsDetailsPending(true);

    const getEmissionsDataRequestBody = {
      org_id: asset?.org_id,
      site_id: asset?.site_id,
      asset_id: asset?.asset_id,
      device_id: asset?.device_id,
      tags: {
        emissionsData: {
          tag_id: 2135,
          key: calculationTime,
          agg_func: 'last_value',
        },
      },
    };

    try {
      const response = await getCarbonCaptureDetails({
        body: getEmissionsDataRequestBody,
        accessToken: currentSession?.token,
      });

      if (!response.ok) {
        throw new Error(`Fetch failed with status: ${response.status}`);
      }

      const responseData = await response.json();

      setEmissionsDetails(responseData?.[0]?.emissionsData);
    } catch (error) {
      console.error('Error fetching emission data:', error);
    } finally {
      setEmissionsDetailsPending(false);
    }
  };

  const getUnitProfilesLink = async () => {
    try {
      const response = await getUnitProfileLink({
        accessToken: currentSession?.token,
        netsuiteId: enterpriseObject?.netsuiteId,
      });

      if (!response.ok) {
        throw new Error(`Fetch failed with status: ${response.status}`);
      }

      const responseData = await response.json();
      const currentLink = generateUnitProfilesLink(responseData?.netsuite_id);

      setUnitProfilesLink(currentLink);
    } catch (error) {
      console.error('Error unit profile link data:', error);
    }
  };

  const getCoordinates = async () => {
    try {
      const response = await getUnitProfileInfo({
        accessToken: currentSession?.token,
        body: {
          tags: coordinateTags,
          ...sharedRequestBody(asset),
        },
      });
      const [responseData] = await response.json();
      const { lat, lon } = responseData;
      if (lat && lon) setCoordinates({ lat, lng: lon });
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  };

  useEffect(() => {
    if (currentSession?.token) {
      enterpriseObject && getUnitProfilesLink();
      !emissionsDetails && asset && getEmissionsData();
      asset && checkDigitalTwinExistence(asset?.asset_name, setDigitalTwinExistence);
      asset && getCoordinates();
    }
  }, [currentSession, asset, enterpriseObject]);

  useEffect(() => {
    !fetchedSubscriptions && getSubscriptions();
  }, [fetchedSubscriptions]);

  return (
    <div className="asset-page">
      <div className="asset-page--spacer" />
      <UnitHeader
        asset={asset}
        coordinates={coordinates}
        addIpCopyToast={addIpCopyToast}
        hasEditIpAccess={hasEditIpAccess}
        enterpriseObject={enterpriseObject}
        unitProfilesLink={unitProfilesLink}
        setNotificationModalOpen={setNotificationModalOpen}
      />
      <Tabs
        defaultActiveKey={Number.isNaN(parseInt(navPath)) ? navPath : 'telemetry'}
        headerSize="h5"
        color="black"
      >
        <Tab
          eventKey="telemetry"
          title="Telemetry"
          onClick={() => navigate(singleAssetRouteNames.TELEMETRY)}
        />
        <Tab
          eventKey="events"
          title="Events"
          onClick={() => navigate(singleAssetRouteNames.EVENTS)}
        />
        <Tab
          eventKey="work-orders"
          title="Work Orders"
          onClick={() => navigate(singleAssetRouteNames.WORK_ORDERS)}
        />
        {hasNewEmissionAccess && (
          <Tab
            eventKey="emissions-calculator"
            title="Emissions Calculator"
            onClick={() => navigate(singleAssetRouteNames.EMISSIONS_CALC)}
          />
        )}
        <Tab
          eventKey="more-information"
          title="More Information"
          onClick={() => navigate(singleAssetRouteNames.MORE_INFORMATION)}
        />
        {digitalTwinExistence && (
          <Tab
            eventKey="digital-twin"
            title="3D Digital Twin"
            onClick={() => navigate(singleAssetRouteNames.DIGITAL_TWIN)}
          />
        )}
      </Tabs>
      <AssetRoutes enterpriseObject={enterpriseObject} asset={asset} />
      <NotificationModal
        asset={asset}
        subscriptions={subscriptions}
        isOpen={notificationModalOpen}
        closeModal={closeNotificationModal}
        updateSubscriptions={updateUserSubscriptions}
      />
      <CarbonCaptureModal
        showDataError={showDataError}
        closeModal={closeCarbonModal}
        isOpen={carbonCaptureModalOpen}
        getEmissionsData={getEmissionsData}
        defaultEmissionsDetails={emissionsDetails}
        emissionDetailsPending={emissionsDetailsPending}
        currentCarbonCaptureTime={currentCarbonCaptureTime}
        setCurrentCarbonCaptureTime={setCurrentCarbonCaptureTime}
      />
    </div>
  );
};

export default AssetPage;
