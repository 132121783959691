import { useSelector } from 'react-redux';
import React, { useContext, useEffect, useState } from 'react';

import { selectSession } from 'shared/reducers/sessionReducer';
import { fetchTagById } from 'shared/repositories/axilTagRepository';
import { fetchLayoutByDashboardId } from 'shared/repositories/axilLayoutRepository';
import { fetchChartMetrics, fetchSeriesByDate, modifyTelemetryCharts } from 'shared/repositories/axilSeriesRepository';

import {
  getObjectBasedOnNumber,
} from 'single-asset/helpers/telemetryHelpers';
import {
  STATUS_STAT_ID,
  FAULTED_STAT_ID,
  RUNTIME_STAT_ID,
  ELEVATION_STAT_ID,
  ENGINE_HOURS_STAT_ID,
  ELECTRIC_SPEED_STAT_ID,
  TELEMETRY_DASHBOARD_ID,
  DISCHARGE_TEMP_STAT_ID,
  APPLICATION_TYPE_STAT_ID,
  SUCTION_PRESSURE_STAT_ID,
  DISCHARGE_PRESSURE_STAT_ID,
} from 'single-asset/constants/telemetryConstants';
import { ChartingContext } from 'context/ChartingContext';
import { getDefaultDateRange } from 'single-asset/helpers/singleAssetHelpers';

import UnitStatList from 'single-asset/components/asset/UnitStatList';
import ChartSkeleton from 'single-asset/components/charts/ChartSkeleton';
import ChartsContainer from 'single-asset/components/charts/ChartsContainer';

import './TelemetryPage.scss';

const TelemetryPage = ({ enterpriseObject, asset }) => {
  const currentSession = useSelector(selectSession);
  const { charting } = useContext(ChartingContext);

  const [unitStats, setUnitStats] = useState([]);
  const [unitStatsLoading, setUnitStatsLoading] = useState(false);

  const [unitStatusStat, setUnitStatusStat] = useState(null);
  const [unitFaultedStat, setUnitFaultsStat] = useState(null);
  const [unitRuntimeStat, setUnitRuntimeStat] = useState(null);
  const [unitElevationStat, setUnitElevationStat] = useState(null);
  const [unitEngineHoursStat, setUnitEngineHoursStat] = useState(null);
  const [unitDischargeTempStat, setUnitDischargeTempStat] = useState(null);
  const [unitElectricSpeedStat, setUnitElectricSpeedStat] = useState(null);
  const [unitSuctionPressureStat, setUnitSuctionPressureStat] = useState(null);
  const [unitApplicationTypeStat, setUnitApplicationTypeStat] = useState(null);
  const [unitDischargePressureStat, setUnitDischargePressureStat] = useState(null);
  const [chartsLoading, setChartsLoading] = useState(false);

  const getAndSetUnitStat = async (tagId, setStatFunction, errorMessage) => {
    setUnitStatsLoading(true);

    const tagConfig = getObjectBasedOnNumber(tagId);

    const requestBody = {
      agg: true,
      tags: tagConfig,
      asset_id: enterpriseObject?.assetId,
      device_id: enterpriseObject?.deviceId,
      site_id: enterpriseObject?.site?.siteId,
      org_id: enterpriseObject?.organization?.orgId,
      device_type_id: enterpriseObject?.assetTypeId,
    };

    try {
      const response = await fetchTagById({
        body: requestBody,
        accessToken: currentSession?.token,
      });

      if (!response.ok) {
        throw new Error(`${errorMessage}: ${response.status}`);
      }

      const responseData = await response.json();

      setStatFunction(responseData?.[0]);
    } catch (error) {
      console.error(`${errorMessage}:`, error);
    } finally {
      setUnitStatsLoading(false);
    }
  };

  const getUnitStatusStat = () => {
    getAndSetUnitStat(STATUS_STAT_ID, setUnitStatusStat, 'Error fetching Unit Status stat');
  };

  const getUnitFaultsStat = () => {
    getAndSetUnitStat(FAULTED_STAT_ID, setUnitFaultsStat, 'Error fetching Unit Faulted stat');
  };

  const getUnitRuntimeStat = async () => {
    getAndSetUnitStat(RUNTIME_STAT_ID, setUnitRuntimeStat, 'Error fetching Unit Runtime stat');
  };

  const getUnitElevationStat = async () => {
    getAndSetUnitStat(ELEVATION_STAT_ID, setUnitElevationStat, 'Error fetching Unit Elevation stat');
  };

  const getUnitApplicationType = async () => {
    getAndSetUnitStat(APPLICATION_TYPE_STAT_ID, setUnitApplicationTypeStat, 'Error fetching Unit Application Type stat');
  };

  const getUnitDischargeTempStat = async () => {
    getAndSetUnitStat(DISCHARGE_TEMP_STAT_ID, setUnitDischargeTempStat, 'Error fetching Unit Discharge Temp stat');
  };

  const getUnitElectricSpeedStat = async () => {
    getAndSetUnitStat(ELECTRIC_SPEED_STAT_ID, setUnitElectricSpeedStat, 'Error fetching Unit Electric Speed stat');
  };

  const getUnitEngineHoursStat = async () => {
    getAndSetUnitStat(ENGINE_HOURS_STAT_ID, setUnitEngineHoursStat, 'Error fetching Unit Engine Hours stat');
  };

  const getUnitSuctionPressureStat = async () => {
    getAndSetUnitStat(SUCTION_PRESSURE_STAT_ID, setUnitSuctionPressureStat, 'Error fetching Unit Suction Pressure stat');
  };

  const getUnitDischargePressureStat = async () => {
    getAndSetUnitStat(DISCHARGE_PRESSURE_STAT_ID, setUnitDischargePressureStat, 'Error fetching Unit Discharge Pressure stat');
  };

  const getUnitStats = () => {
    getUnitStatusStat();
    getUnitFaultsStat();
    getUnitRuntimeStat();
    getUnitElevationStat();
    getUnitEngineHoursStat();
    getUnitApplicationType();
    getUnitElectricSpeedStat();
    getUnitDischargeTempStat();
    getUnitSuctionPressureStat();
    getUnitDischargePressureStat();
  };

  const defaultDates = getDefaultDateRange();

  const [customMetrics, setCustomMetrics] = useState(null);
  const [unitChartConfig, setUnitChartConfig] = useState(null);
  const [unitChartsLayout, setUnitChartsLayout] = useState(null);
  const [chartLayout, setChartLayout] = useState(null);
  const [chartBodies, setChartBodies] = useState(null);

  const [charts, setCharts] = useState([]);
  const [chartsRange, setChartsRange] = useState(null);
  const [chartDates, setChartDates] = useState(defaultDates);

  const getChart = async ({
    url,
    label,
    dates,
    series,
  // eslint-disable-next-line consistent-return
  }) => {
    const getChartRequestBody = {
      series,
      stop: dates[1],
      start: dates[0],
    };

    try {
      const response = await fetchSeriesByDate({
        url,
        body: getChartRequestBody,
        accessToken: currentSession?.token,
      });

      if (!response.ok) {
        throw new Error(`Error fetching Chart ${label}: ${response.status}`);
      }

      const responseData = await response.json();

      return responseData;
    } catch (error) {
      console.error(`Error fetching Chart ${label}:`, error);
    }
  };

  const getCharts = async ({
    dates,
    chartRequestBodies = chartBodies,
  }) => {
    setChartsLoading(true);
    const currentCharts = await Promise.all(chartRequestBodies?.map((requestBody) => getChart({
      dates,
      url: requestBody?.url,
      series: requestBody?.series,
    })));
    setChartsLoading(false);
    setCharts(currentCharts);
  };

  const getChartsConfig = async () => {
    const getChartsConfigRequestBody = {
      org_id: asset?.org_id,
      site_id: asset?.site_id,
      asset_id: asset?.asset_id,
      device_id: asset?.device_id,
      asset_type_id: asset?.asset_type_id,
      dashboard_id: TELEMETRY_DASHBOARD_ID,
    };

    try {
      const response = await fetchLayoutByDashboardId({
        body: getChartsConfigRequestBody,
        accessToken: currentSession?.token,
      });

      if (!response.ok) {
        throw new Error(`Error fetching Charts Config: ${response.status}`);
      }

      const responseData = await response.json();
      const filteredResponse = responseData?.fluxCharts || responseData?.xl;

      filteredResponse && setUnitChartConfig(filteredResponse);
      filteredResponse && setUnitChartsLayout(filteredResponse);
    } catch (error) {
      console.error('Error fetching Charts Config:', error);
    }
  };

  const getCustomMetrics = async () => {
    const getMetricsRequestBody = {
      org_id: asset?.org_id,
      site_id: asset?.site_id,
      asset_id: asset?.asset_id,
      device_id: asset?.device_id,
    };

    try {
      const response = await fetchChartMetrics({
        body: getMetricsRequestBody,
        accessToken: currentSession?.token,
      });

      if (!response.ok) {
        throw new Error(`Error fetching Chart Data: ${response.status}`);
      }

      const responseData = await response.json();
      setCustomMetrics(responseData);
    } catch (error) {
      console.error('Error fetching Chart Data:', error);
    }
  };

  const updateChartsLayout = async (newChartsLayout, updateAllCharts = false) => {
    setCharts([]);

    const updateChartsLayoutRequestBody = {
      dashboard_id: TELEMETRY_DASHBOARD_ID,
      device_id: asset?.device_id?.toString(),
      layout: { fluxCharts: newChartsLayout },
      org_id: updateAllCharts ? -1 : asset?.org_id?.toString(),
      site_id: updateAllCharts ? -1 : asset?.site_id?.toString(),
      asset_type_id: updateAllCharts ? -1 : asset?.asset_type_id,
      asset_id: updateAllCharts ? -1 : asset?.asset_id?.toString(),
    };

    try {
      const response = await modifyTelemetryCharts({
        accessToken: currentSession?.token,
        body: updateChartsLayoutRequestBody,
      });

      if (!response.ok) {
        throw new Error(`Error updating Charts Layout: ${response.status}`);
      }

      await response.json();
    } catch (error) {
      console.error('Error updating Charts Layout:', error);
    } finally {
      // fetch charts with new layout here
    }
  };

  useEffect(() => {
    currentSession?.token && asset && getUnitStats();
    currentSession?.token && asset && getChartsConfig();
    currentSession?.token && asset && getCustomMetrics();
  }, [currentSession, asset]);

  useEffect(() => {
    chartDates !== defaultDates
      && getCharts({ dates: chartDates, chartRequestBodies: chartBodies });
  }, [chartDates]);

  useEffect(() => {
    if (!chartBodies) return;
    !chartsLoading && getCharts({ dates: defaultDates, chartRequestBodies: chartBodies });
  }, [chartBodies]);

  useEffect(() => {
    if (asset) {
      const { layout, chartBodies: bodies } = (asset && charting.getUnitLayoutAndApiChartBodies({
        ...asset,
        driver: (asset?.driver ?? enterpriseObject?.driver) ?? 'GED',
      })) || {};
      setChartLayout(layout);
      setChartBodies(bodies);
    }
  }, [asset]);

  useEffect(() => {
    const newStats = [
      { name: 'RTP', stat: unitRuntimeStat },
      { name: 'Status', stat: unitStatusStat },
      { name: 'Faulted', stat: unitFaultedStat },
      { name: 'Elevation', stat: unitElevationStat },
      { name: 'Engine Hours', stat: unitEngineHoursStat },
      { name: 'Electric Speed', stat: unitElectricSpeedStat },
      { name: 'Suction Pressure', stat: unitSuctionPressureStat },
      { name: 'Application Type', stat: unitApplicationTypeStat },
      { name: 'Discharge Temperature', stat: unitDischargeTempStat },
      { name: 'Discharge Pressure', stat: unitDischargePressureStat },
    ];

    setUnitStats(newStats);
  }, [
    unitStatusStat,
    unitRuntimeStat,
    unitFaultedStat,
    unitElevationStat,
    unitEngineHoursStat,
    unitElectricSpeedStat,
    unitDischargeTempStat,
    unitSuctionPressureStat,
    unitApplicationTypeStat,
    unitDischargePressureStat,
  ]);

  return (
    <div className="telemetry-page">
      <UnitStatList
        unitStats={unitStats}
        statsLoading={unitStatsLoading}
      />
      {!chartsLoading && customMetrics && charts?.length
        ? (
          <ChartsContainer
            charts={charts}
            getCharts={getCharts}
            chartDates={chartDates}
            asset={enterpriseObject}
            chartsRange={chartsRange}
            setChartDates={setChartDates}
            customMetrics={customMetrics}
            setChartsRange={setChartsRange}
            unitChartConfig={unitChartConfig}
            unitChartsLayout={unitChartsLayout}
            updateChartsLayout={updateChartsLayout}
            setUnitChartsLayout={setUnitChartsLayout}
            legacyLayout={unitChartConfig?.data?.completeLegacyResponse}
            chartLayout={chartLayout}
          />
        )
        : <ChartSkeleton />}
    </div>
  );
};

export default TelemetryPage;
