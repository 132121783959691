import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { selectSession } from 'shared/reducers/sessionReducer';
import { fetchUnits } from 'shared/repositories/assetsRepository';
import { selectPermissions } from 'shared/reducers/permissionsSlice';
import { addNotification } from 'shared/actions/notificationsActions';
import { selectSubscriptions } from 'shared/reducers/subscriptionsSlice';
import { fetchEnterpriseObject } from 'shared/repositories/enterpriseObjectRepository';
import { editUserSubscriptions, fetchUserSubscriptions } from 'shared/actions/subscriptionsActions';

import AssetPage from 'single-asset/pages/AssetPage';
import { checkIpEditPermissions } from 'single-asset/helpers/singleAssetHelpers';
import { IP_COPY_SUCCESS_MESSAGE } from 'single-asset/constants/headerConstants';
import { ChartingContext } from 'context/ChartingContext';

const AssetPageContainer = ({ user }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { charting } = useContext(ChartingContext);

  const session = useSelector(selectSession);
  const { groups } = useSelector(selectPermissions);
  const { fetchedSuccessfully: fetchedSubscriptions, subscriptions } = useSelector(selectSubscriptions);

  const getSubscriptions = () => dispatch(fetchUserSubscriptions());
  const updateUserSubscriptions = (newSubscriptions) => dispatch(editUserSubscriptions(newSubscriptions));
  const addIpCopyToast = () => dispatch(addNotification({ type: 'success', message: IP_COPY_SUCCESS_MESSAGE }));

  const [units, setUnits] = useState([]);
  const [asset, setAsset] = useState(null);
  const [unitsLoading, setUnitsLoading] = useState(false);
  const [enterpriseObject, setEnterpriseObject] = useState(null);
  const [enterpriseObjectLoading, setEnterpriseObjectLoading] = useState(false);

  const getUnits = async (filters = []) => {
    setUnitsLoading(true);

    try {
      const response = await fetchUnits({ accessToken: session?.token, body: filters });

      if (!response.ok) {
        throw new Error(`Fetch failed with status: ${response.status}`);
      }

      const responseData = await response.json();
      const filteredResponseData = responseData?.filter((entry) => entry.invalidated_at === null);

      setUnits(filteredResponseData);
    } catch (error) {
      console.error('Error fetching assets:', error);
    } finally {
      setUnitsLoading(false);
    }
  };

  const getEnterpriseObject = async (name) => {
    setEnterpriseObjectLoading(true);

    try {
      const response = await fetchEnterpriseObject({ accessToken: session?.token, body: { assetName: name } });

      if (!response.ok) {
        throw new Error(`Fetch failed with status: ${response.status}`);
      }

      const responseData = await response.json();

      setEnterpriseObject(responseData?.assets?.[0]);
    } catch (error) {
      console.error('Error fetching enterprise objects:', error);
    } finally {
      setEnterpriseObjectLoading(false);
    }
  };

  useEffect(() => {
    session?.token && getUnits();
  }, [session]);

  useEffect(() => {
    const singleAsset = units
      ?.find((entry) => entry?.device_id?.toString() === params?.device_id?.toString()
        && entry?.site_id?.toString() === params?.site_id?.toString());

    !asset && singleAsset && setAsset(singleAsset);

    if (singleAsset && !enterpriseObject) {
      const currentEnterpriseObject = getEnterpriseObject(singleAsset?.asset_name);
      currentEnterpriseObject && setEnterpriseObject(currentEnterpriseObject);
    }
  }, [units, params]);

  return (
    <section>
      {enterpriseObjectLoading || unitsLoading || (charting?.layouts === undefined)
        ? (
          <div style={{
            zIndex: '200',
            margin: '40px',
            position: 'absolute',
          }}
          >
            <h3>Loading Unit View . . .</h3>
          </div>
        )
        : (
          <AssetPage
            user={user}
            asset={asset}
            subscriptions={subscriptions}
            addIpCopyToast={addIpCopyToast}
            enterpriseObject={enterpriseObject}
            getSubscriptions={getSubscriptions}
            fetchedSubscriptions={fetchedSubscriptions}
            hasEditIpAccess={checkIpEditPermissions(groups)}
            updateUserSubscriptions={updateUserSubscriptions}
          />
        )}
    </section>
  );
};

export default AssetPageContainer;
