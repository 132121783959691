import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  assetListStatusOptionDropdownItems,
  defaultAssetListStatusDropdownOption,
} from 'shared/constants/assetConstants';
import SearchableDropdown from 'shared/components/SearchableDropdown';

import { removeAreaParameter } from 'dashboard/helpers/dashboardHelpers';

import './AssetFilterDropdowns.scss';

const AssetFilterDropdowns = ({
  getUnits,
  filterAreas,
  dashboardAreaFilter,
  selectedStatusFilter,
  updateAreaFilter,
  setSelectedStatusFilter,
}) => {
  const navigate = useNavigate();

  const { search, pathname } = useLocation();
  const currentUrl = `${pathname}${search}`;

  const [areaFilterString, setAreaFilterString] = useState('');

  const allAreasOption = { label: 'All Areas', value: -1 };
  const areaOptions = [allAreasOption, ...filterAreas.map(({ label, value }) => ({
    label,
    value,
    key: value,
  }))];

  const resetAreaFilter = () => {
    updateAreaFilter(-1);
    getUnits({ filters: [] });

    const newUrl = removeAreaParameter(currentUrl);
    navigate(newUrl);
  };

  const handleAreaSelect = (newGroup) => {
    updateAreaFilter(newGroup);
    getUnits({ filters: [newGroup] });
  };

  const handleStatusSelect = (newStatus) => {
    setSelectedStatusFilter(newStatus);
  };

  const handleAreaSearch = ({ target: { value } }) => {
    setAreaFilterString(value);
  };

  return (
    <div className="asset-filter-dropdowns">
      <SearchableDropdown
        itemHeader="Areas"
        variant="blue-slim"
        selectedHasCheckmark
        placement="bottomLeft"
        searchValue={areaFilterString}
        defaultOption={allAreasOption}
        onItemSelect={handleAreaSelect}
        resetToDefault={resetAreaFilter}
        onSearchChange={handleAreaSearch}
        searchPlaceholder='Search "Permian Basin"'
        dropdownValue={areaOptions.find((group) => Number(group.value) === Number(dashboardAreaFilter))?.label}
        dropdownItems={areaOptions.filter((group) => group.label.toLowerCase().includes(areaFilterString.toLowerCase()))}
      />
      <SearchableDropdown
        variant="blue-slim"
        itemHeader="Activity"
        isSearchable={false}
        selectedHasCheckmark
        placement="bottomLeft"
        onItemSelect={handleStatusSelect}
        dropdownItems={assetListStatusOptionDropdownItems}
        defaultOption={defaultAssetListStatusDropdownOption}
        resetToDefault={() => handleStatusSelect(defaultAssetListStatusDropdownOption.value)}
        dropdownValue={assetListStatusOptionDropdownItems.find((option) => option.value === selectedStatusFilter).label}
      />
    </div>
  );
};

export default AssetFilterDropdowns;
