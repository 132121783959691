export const getUniqueValuesWithLabel = (key: string, data: any, filter: string = '') => {
  const filteredData = data.filter((item: any) => {
    const value = item[key];
    if (typeof value === 'string' || typeof value === 'number') {
      return value.toString().toLowerCase().includes(filter?.toString()?.toLowerCase());
    }
    return false;
  });

  const uniqueValues = [...new Set(filteredData.map((item: any) => item[key]))];

  return uniqueValues.map((value) => {
    const item = data.find((entry: any) => entry[key] === value);
    let label;

    switch (key) {
      case 'asset_id': {
        label = `Asset - ${item.asset_id}`;
        break;
      }
      case 'asset_name': {
        label = `Asset - ${item.asset_name}`;
        break;
      }
      case 'site_name': {
        label = item.site_name;
        break;
      }
      case 'org_name': {
        label = item.org_name;
        break;
      }
      default: break;
    }

    return {
      label,
      type: key,
      value: item[key],
      org_id: item.org_id,
      site_id: item.site_id,
      asset_id: item.asset_id,
      org_name: item.org_name,
      device_id: item.device_id,
      site_name: item.site_name,
      asset_name: item.asset_name,
    };
  });
};
