import { useState } from 'react';
import { Modal, Table } from 'antd';
import { IoCloseOutline } from 'react-icons/io5';

import { Button } from '@flogistix/flo-ui';

import { createToggles } from 'shared/helpers/notificationHelpers';
import { NOTIFICATION_COLUMNS } from 'single-asset/constants/headerConstants';

import './NotificationModal.scss';

const NotificationModal = ({
  asset,
  isOpen,
  closeModal,
  subscriptions,
  updateSubscriptions,
}) => {
  const [modifiedSubscriptions, setModifiedSubscriptions] = useState(subscriptions);
  const handleConfirm = () => {
    updateSubscriptions({ subscriptions: modifiedSubscriptions });
    closeModal();
  };

  const handleClose = () => {
    setModifiedSubscriptions(subscriptions);
    closeModal();
  };

  return (
    <Modal
      centered
      footer={[]}
      open={isOpen}
      closable={false}
      onCancel={handleClose}
      className="notification-modal"
      wrapClassName="notification-modal-dialog"
    >
      <div className="modal-head">
        <div className="header-text">
          <h2>Asset notification</h2>
          <p>This applies only to this asset.</p>
        </div>
        <IoCloseOutline onClick={handleClose} className="close-icon" size={40} />
      </div>
      <div className="modal-content">
        <Table
          pagination={false}
          columns={NOTIFICATION_COLUMNS}
          dataSource={[{
            key: asset?.asset_id,
            ...createToggles(asset, modifiedSubscriptions, setModifiedSubscriptions),
          }]}
        />
        <div className="action-buttons">
          <Button fixedWidth="49.5%" variation="gray" onClick={handleClose}>Cancel</Button>
          <Button fixedWidth="49.5%" onClick={handleConfirm}>Save</Button>
        </div>
      </div>
    </Modal>
  );
};

export default NotificationModal;
