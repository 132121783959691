import {
  CARBON_CAPTURE_SUMMATION_SYMBOL,
  CARBON_CAPTURE_FLARED_EMISSIONS_LABEL,
} from 'single-asset/constants/carbonCaptureConstants';
import CarbonCalculatorPill from 'single-asset/components/carbon-capture-calculator/modal/components/CarbonCalculatorPill';
import CarbonCalculatorEquation from 'single-asset/components/carbon-capture-calculator/modal/components/CarbonCalculatorEquation';

const EquationTableRow = ({
  label,
  equation,
  width = '80%',
}) => (
  <section className="equation-breakdown-card">
    <div
      style={{ width }}
      className="equation-row"
    >
      <h1 className="equation-row-label">
        {label}
      </h1>
      <div className="equation-breakdown-container">
        {label.props.children === CARBON_CAPTURE_FLARED_EMISSIONS_LABEL.props.children
          && (
            <div className="equation-summation">
              <CarbonCalculatorPill
                style={{ marginRight: '5px' }}
                label={CARBON_CAPTURE_SUMMATION_SYMBOL}
              />
            </div>
          )}
        <div className="equation-components">
          {equation?.map(((entry) => (
            <CarbonCalculatorEquation
              entry={entry}
              key={entry.description.props.children}
            />
          )))}
        </div>
      </div>
    </div>
  </section>
);

export default EquationTableRow;
